import { useEffect, useState } from "react";
import {
  useCartContext,
  useUpdateCartQuantityContext,
} from "../../context/cart";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { HeaderComponent } from "../components/client/HeaderComponent";
import { ProductCart } from "../../domain/models/ProductCart";
import { useClientModelController } from "../hook/useClientModelController";
import { mailIsValid, phoneIsValid } from "../../utils/fieldUtils";
import { showErrorToast } from "../../utils/toastUtils";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import DatePickerCustom from "../components/DatePickerCustom";
import { useNavigate } from "react-router-dom";
import { Set } from "../../domain/models/Set";
import { getCategoryFromFullPath } from "../../utils/productUtils";
import CustomTimePicker from "../components/client/CustomTimePicker";

export function CheckoutPage({
  productsRepository,
  quotesRepository,
}: {
  productsRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();
  const [cartProducts, setCartProducts] = useState<ProductCart[]>([]);
  const [fullName, setFullName] = useState<string>("");
  const [fullNameValid, setFullNameValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [phoneValid, setPhoneValid] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [cap, setCap] = useState<string>("");

  const [clientCategory, setClientCategory] = useState<number>(0);
  const [pIVA, setPIVA] = useState<string>("");
  const [codUnivoco, setCodUnivoco] = useState<string>("");
  const [codFiscale, setCodFiscale] = useState<string>("");

  const [shippingAddress, setShippingAddress] = useState<string>("");

  const [shippingCity, setShippingCity] = useState<string>("");
  const [shippingCap, setShippingCap] = useState<string>("");

  const [deliveryCategory, setDeliveyCategory] = useState<number>(0);

  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [deliveryTime, setDeliveryTime] = useState<string>("");
  const [eventDate, setEventDate] = useState<Date | null>(null);
  const [returnDate, setReturnDate] = useState<Date | null>(null);
  const [returnTime, setReturnTime] = useState<string>("");
  const [localPickup, setLocalPickup] = useState<boolean>(false);

  const [hasSubmit, setHasSubmit] = useState<boolean>(false);
  const [isCreatingQuoteRequest, setIsCreatingQuoteRequest] =
    useState<boolean>(false);

  const { handleCreatePendingQuote } = useClientModelController(
    productsRepository,
    quotesRepository,
  );

  const cart = useCartContext();
  const updateCartQuantity = useUpdateCartQuantityContext();
  const [sets, setSets] = useState<Set[] | null>(null);
  const { handleGetAllSets } = useClientModelController(
    productsRepository,
    quotesRepository,
  );
  useEffect(() => {
    if (!sets) {
      getSets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSets = async () => {
    const setsResult = await handleGetAllSets();
    setSets(setsResult);
  };
  useEffect(() => {
    let cartItems: ProductCart[] = [];
    cart.forEach((item) => {
      cartItems.push(item);
    });
    setCartProducts(cartItems);
  }, [cart]);

  useEffect(() => {
    const isNameValid = fullName.trim().length > 3;
    const isEmailValid = mailIsValid(email);
    const isPhoneValid = phoneIsValid(phone);

    setFullNameValid(isNameValid);
    setEmailValid(isEmailValid);
    setPhoneValid(isPhoneValid);
  }, [
    fullName,
    email,
    phone,
    clientCategory,
    pIVA,
    codUnivoco,
    codFiscale,
    address,
    city,
    cap,
    shippingAddress,
    shippingCity,
    shippingCap,
    deliveryDate,
    deliveryTime,
    eventDate,
    returnDate,
    returnTime,
  ]);

  const calculateTotal = (): string => {
    let total = 0;

    cartProducts.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });

    return total.toFixed(2).replace(".", ",");
  };

  const calculateTax = (): string => {
    let total = 0;

    cartProducts.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });

    return (total * 0.22).toFixed(2).replace(".", ",");
  };

  const calculateTotalWithTax = (): string => {
    let total = 0;

    cartProducts.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });
    let tax = total * 0.22;
    return (total + tax).toFixed(2).replace(".", ",");
  };

  const validateForm = (): boolean => {
    setHasSubmit(true);
    const isNameValid = fullName.length > 3;
    const isEmailValid = mailIsValid(email);
    const isPhoneValid = phoneIsValid(phone);

    return isNameValid && isEmailValid && isPhoneValid;
  };

  const createPendingQuotes = async () => {
    setIsCreatingQuoteRequest(true);

    const result = await handleCreatePendingQuote({
      name: fullName.trim(),
      email,
      phone,
      pIVA,
      codUnivoco,
      codFiscale,
      address,
      city,
      cap,
      cart: cartProducts,
      date: Date.now(),
      shippingAddress,
      deliveryDate: deliveryDate ? deliveryDate.getTime() : null,
      deliveryTime,
      eventDate: eventDate ? eventDate.getTime() : null,
      returnDate: returnDate ? returnDate.getTime() : null,
      returnTime,
      shippingCity,
      shippingCap,
      localPickup,
    });
    if (result) {
      navigate("/thankYou");
      resetAllFields();
      for (var cartItem of cartProducts) {
        updateCartQuantity(cartItem.id, 0);
      }
      setIsCreatingQuoteRequest(false);
    } else {
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo d i riprovare più tardi.",
      );
      setIsCreatingQuoteRequest(false);
    }
  };

  const resetAllFields = () => {
    setFullName("");
    setEmail("");
    setPhone("");
    setPIVA("");
    setCodUnivoco("");
    setCodFiscale("");
    setAddress("");
    setCity("");
    setCap("");
    setShippingAddress("");
    setShippingCity("");
    setShippingCap("");
    setDeliveryDate(null);
    setDeliveryTime("");
    setEventDate(null);
    setReturnDate(null);
    setReturnTime("");
    setLocalPickup(false);
    setHasSubmit(false);
  };

  const handleEventDateSelect = (date: number, calendarIndex: number) => {
    const selectedDate = new Date(date);

    if (calendarIndex === 1) {
      setDeliveryDate(selectedDate);
      setEventDate(null); // Reset subsequent dates
      setReturnDate(null);
    } else if (calendarIndex === 2) {
      setEventDate(selectedDate);
      setReturnDate(null); // Reset subsequent dates
    } else if (calendarIndex === 3) {
      setReturnDate(selectedDate);
    }
  };

  const getSetFromProduct = (product: ProductCart): Set | null => {
    const set = sets?.filter(
      (set) =>
        set.name.toLowerCase() === getCategoryFromFullPath(product.category),
    );
    return set ? set[0] : null;
  };

  const groupProductsBySet = (products: ProductCart[]) => {
    const grouped: { [key: string]: ProductCart[] } = {};

    products.forEach((product) => {
      const set = getSetFromProduct(product);
      if (set) {
        if (!grouped[set.name]) {
          grouped[set.name] = [];
        }
        grouped[set.name].push(product);
      } else {
        if (!grouped[product.id]) {
          grouped[product.id] = [];
        }
        grouped[product.id].push(product);
      }
    });

    return grouped;
  };

  const groupedProducts = groupProductsBySet(cartProducts);

  return (
    <div className="min-h-full w-full">
      <HeaderComponent
        productsRepository={productsRepository}
        quotesRepository={quotesRepository}
      />
      <div className="grid gap-10 px-4 sm:px-16 xl:grid-cols-2">
        <div className="pt-8">
          <p className="text-xl font-medium">Riepilogo ordine</p>
          <p className="text-gray-400">
            Ecco l'elenco dei prodotti che hai richiesto.
          </p>
          <div className="mt-8 space-y-2 rounded-lg border bg-white px-2 sm:px-6">
            {groupedProducts ? (
              Object.entries(groupedProducts).map(
                ([groupKey, groupProducts]) => {
                  const set = sets?.find((set) => set.name === groupKey);
                  return (
                    <li key={groupKey} className="flex flex-col py-6">
                      <div className="flex items-start">
                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                          <img
                            src={set ? set.image : groupProducts[0].image}
                            alt={set ? set.image : groupProducts[0].image}
                            className="h-full w-full object-contain object-center"
                          />
                        </div>
                        <div className="ml-4 flex flex-1 flex-col">
                          <div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <h3>
                                <a
                                  href={
                                    set
                                      ? set.subcategory
                                        ? `/catalog/${set.category}/${set.subcategory}/${set.name}`
                                        : `/catalog/${set.category}/${set.name}`
                                      : `/catalog/${groupProducts[0].id}`
                                  }
                                >
                                  {set ? set.name : groupProducts[0].title}
                                </a>
                              </h3>
                              <p className="ml-4">
                                {groupProducts
                                  .reduce(
                                    (total, product) =>
                                      total + product.price * product.quantity,
                                    0,
                                  )
                                  .toFixed(2)
                                  .replace(".", ",")}
                                €
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="mt-2 space-y-2">
                        {groupProducts.map((product) => (
                          <li
                            key={product.id}
                            className="flex items-center text-sm"
                          >
                            <div className="flex w-full justify-end">
                              <p className="font-medium text-gray-500">
                                {product.title}
                              </p>
                              <p className="ml-4 text-gray-500">
                                Qtà {product.quantity}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                },
              )
            ) : (
              <p className="font-semibold">
                Non hai ancora aggiunto nessun prodotto al carrello
              </p>
            )}
          </div>
        </div>
        <div className="mb-6 mt-4 rounded-lg bg-gray-50 px-2 pt-8 sm:px-10 xl:mt-0 xl:rounded-b-lg xl:rounded-t-none">
          <p className="text-xl font-medium">Informazioni di contatto</p>
          <p className="text-gray-400">
            Completa l'ordine inserendo le tue informazioni personali per poter
            ricevere il preventivo.
          </p>

          <div className="">
            <label
              htmlFor="full-name"
              className="mb-2 mt-4 block text-sm font-medium"
            >
              Nome e cognome
            </label>
            <div className="relative">
              <input
                type="text"
                id="full-name"
                name="fullname"
                className={`w-full rounded-md border ${
                  !fullNameValid && hasSubmit
                    ? "border-red-500"
                    : "border-gray-200"
                } px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="Mario Rossi"
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
              />
            </div>
            {!fullNameValid && hasSubmit && (
              <span className="text-xs text-red-500">Compila questo campo</span>
            )}
            <label
              htmlFor="email"
              className="mb-2 mt-4 block text-sm font-medium"
            >
              Email
            </label>
            <div className="relative">
              <input
                type="text"
                id="email"
                name="email"
                className={`w-full rounded-md border ${
                  !emailValid && hasSubmit
                    ? "border-red-500"
                    : "border-gray-200"
                } px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="your.email@gmail.com"
                onChange={(e) => setEmail(e.target.value.trim())}
                value={email}
              />
            </div>
            {!emailValid && hasSubmit && (
              <span className="text-xs text-red-500">
                Inserisci un valore valido
              </span>
            )}
            <label
              htmlFor="phone"
              className="mb-2 mt-4 block text-sm font-medium"
            >
              Telefono
            </label>
            <div className="relative">
              <input
                type="text"
                id="phone"
                name="phone"
                className={`w-full rounded-md border ${
                  !phoneValid && hasSubmit
                    ? "border-red-500"
                    : "border-gray-200"
                } px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="333 3333 333"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </div>
            {!phoneValid && hasSubmit && (
              <span className="text-xs text-red-500">
                Inserisci un valore valido
              </span>
            )}

            <label
              htmlFor="category"
              className="mb-2 mt-4 block text-sm font-medium"
            >
              Categoria
            </label>
            <div className="relative flex rounded-lg border bg-gray-200 shadow">
              <div
                className={`absolute bottom-0 top-0 my-0.5 w-1/2 rounded-lg bg-white shadow transition-all duration-300 ${
                  clientCategory === 0 ? "left-0" : "left-1/2"
                }`}
              />

              <button
                onClick={() => setClientCategory(0)}
                className={`z-10 w-1/2 py-2 text-center font-medium transition-colors duration-300 ${
                  clientCategory === 0 ? "text-black" : "text-gray-500"
                }`}
              >
                Azienda
              </button>

              <button
                onClick={() => setClientCategory(1)}
                className={`z-10 w-1/2 py-2 text-center font-medium transition-colors duration-300 ${
                  clientCategory === 1 ? "text-black" : "text-gray-500"
                }`}
              >
                Privato
              </button>
            </div>

            {clientCategory === 0 ? (
              <div className="flex space-x-4">
                <div className="w-full">
                  <label
                    htmlFor="full-name"
                    className="mb-2 mt-4 block text-sm font-medium"
                  >
                    Partita IVA
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="p-iva"
                      name="piva"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      placeholder="###"
                      onChange={(e) => setPIVA(e.target.value)}
                      value={pIVA ?? ""}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="codice-univoco"
                    className="mb-2 mt-4 block text-sm font-medium"
                  >
                    Codice Univoco
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="full-name"
                      name="codUnivoco"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      placeholder="###"
                      onChange={(e) => setCodUnivoco(e.target.value)}
                      value={codUnivoco ?? ""}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <label
                  htmlFor="full-name"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Codice Fiscale
                </label>
                <div className="relative">
                  <input
                    type="text"
                    id="cod-fiscale"
                    name="codFiscale"
                    className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                    placeholder="###"
                    onChange={(e) => setCodFiscale(e.target.value)}
                    value={codFiscale ?? ""}
                  />
                </div>
              </div>
            )}

            <label
              htmlFor="address"
              className="mb-2 mt-4 block text-sm font-medium"
            >
              Indirizzo di fatturazione
            </label>

            <div className="grid grid-cols-5 gap-x-2">
              <div className="col-span-3">
                <input
                  type="text"
                  id="address"
                  name="address"
                  className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Via/Corso"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </div>

              <div>
                <input
                  type="text"
                  name="city"
                  className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Città"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
              </div>
              <div>
                <input
                  type="text"
                  name="zip"
                  className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                  placeholder="CAP"
                  onChange={(e) => setCap(e.target.value.trim())}
                  value={cap}
                />
              </div>
            </div>

            <label
              htmlFor="category"
              className="mb-2 mt-4 block text-sm font-medium"
            >
              Metodo di consegna
            </label>
            <div className="relative flex rounded-lg border bg-gray-200 shadow">
              <div
                className={`absolute bottom-0 top-0 my-0.5 w-1/2 rounded-lg bg-white shadow transition-all duration-300 ${
                  deliveryCategory === 0 ? "left-0" : "left-1/2"
                }`}
              />

              <button
                onClick={() => {
                  setDeliveyCategory(0);
                  setLocalPickup(true);
                }}
                className={`z-10 w-1/2 py-2 text-center font-medium transition-colors duration-300 ${
                  deliveryCategory === 0 ? "text-black" : "text-gray-500"
                }`}
              >
                Ritiro in loco
              </button>

              <button
                onClick={() => {
                  setDeliveyCategory(1);
                  setLocalPickup(false);
                }}
                className={`z-10 w-1/2 py-2 text-center font-medium transition-colors duration-300 ${
                  deliveryCategory === 1 ? "text-black" : "text-gray-500"
                }`}
              >
                Spedizione
              </button>
            </div>

            {deliveryCategory === 1 ? (
              <div>
                {" "}
                <label
                  htmlFor="shipping-address"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Indirizzo di spedizione
                </label>
                <div className="grid grid-cols-5 gap-x-2">
                  <div className="col-span-3">
                    <input
                      type="text"
                      id="shipping-address"
                      name="shipping-address"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      placeholder="Via/Corso"
                      onChange={(e) => setShippingAddress(e.target.value)}
                      value={shippingAddress}
                    />
                  </div>

                  <div>
                    <input
                      type="text"
                      name="shipping-city"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      placeholder="Città"
                      onChange={(e) => setShippingCity(e.target.value)}
                      value={shippingCity}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="shipping-zip"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      placeholder="CAP"
                      onChange={(e) => setShippingCap(e.target.value.trim())}
                      value={shippingCap}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="grid grid-cols-5 gap-x-2">
              <div className="relative col-span-3 overflow-visible">
                <label
                  htmlFor="delivery-date"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Data consegna
                </label>
                <div className="w-full overflow-visible">
                  <DatePickerCustom
                    onDateSelect={(date) =>
                      handleEventDateSelect(date.getTime(), 1)
                    }
                    minDate={null}
                  />
                </div>
              </div>
              <div className="relative col-span-2 overflow-visible">
                <label
                  htmlFor="delivery-date"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Ora consegna
                </label>
                <div className="w-full overflow-visible">
                  <CustomTimePicker
                    onTimeSelect={(time) => setDeliveryTime(time)}
                  />
                </div>
              </div>
            </div>

            <div className="relative overflow-visible">
              <label
                htmlFor="event-date"
                className="mb-2 mt-4 block text-sm font-medium"
              >
                Data evento
              </label>
              <div className="w-full overflow-visible">
                <DatePickerCustom
                  onDateSelect={(date) =>
                    handleEventDateSelect(date.getTime(), 2)
                  }
                  minDate={deliveryDate}
                />
              </div>
            </div>

            <div className="grid grid-cols-5 gap-x-2">
              <div className="relative col-span-3 overflow-visible">
                <label
                  htmlFor="return-date"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Data riconsegna
                </label>
                <div className="w-full overflow-visible">
                  <DatePickerCustom
                    onDateSelect={(date) =>
                      handleEventDateSelect(date.getTime(), 3)
                    }
                    minDate={eventDate}
                  />
                </div>
              </div>
              <div className="relative col-span-2 overflow-visible">
                <label
                  htmlFor="delivery-date"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Ora riconsegna
                </label>
                <div className="w-full overflow-visible">
                  <CustomTimePicker
                    onTimeSelect={(time) => setReturnTime(time)}
                  />
                </div>
              </div>
            </div>

            <div className="mt-6">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">
                  Totale (escluse spese di spedizione)
                </p>

                <p className="text-sm font-semibold text-gray-900">
                  {calculateTotal()}€
                </p>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Iva 22%</p>
                <p className="text-sm font-semibold text-gray-900">
                  {calculateTax()}€
                </p>
              </div>
              <div className="mt-1 flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">
                  Totale inclusa iva
                </p>
                <p className="text-2xl font-semibold text-gray-900">
                  {calculateTotalWithTax()}€
                </p>
              </div>
            </div>
          </div>
          <button
            disabled={isCreatingQuoteRequest}
            onClick={() => {
              if (validateForm()) {
                createPendingQuotes();
              }
            }}
            className="mb-8 mt-4 w-full rounded-md bg-palette-primary px-6 py-3 font-medium text-white hover:bg-palette-dark"
          >
            {isCreatingQuoteRequest
              ? "Invio richiesta..."
              : "Richiedi preventivo"}
          </button>
        </div>
      </div>
    </div>
  );
}
