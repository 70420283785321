import { ProductData } from "../../../domain/models";

export function Price({
  product,
  size,
}: {
  product: ProductData;
  size: string;
}) {
  const discountedPrice =
    product.price - product.price * (product.discount / 100);

  return (
    <div>
      {product.discount > 0 ? (
        <div className="flex flex-col">
          <p className="text-red-600">
            € {discountedPrice.toFixed(2).replace(".", ",")}
            <span className="ml-2 text-palette-dark text-base">
              IVA inclusa
            </span>
          </p>
          <p className="text-base">
            Prezzo normale:{" "}
            <span className="line-through">
              {product.price.toFixed(2).replace(".", ",")} €
            </span>{" "}
            <span className="text-red-600">
              Sconto {product.discount.toFixed(0).replace(".", ",")}%
            </span>
          </p>
        </div>
      ) : (
        <div className={size}>
          <p>a partire da € {product.price.toFixed(2).replace(".", ",")}</p>
        </div>
      )}
    </div>
  );
}
