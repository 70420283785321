import {
  QuerySnapshot,
  DocumentData,
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { app, db } from "../../firebase.config";
import {
  ApiResponse,
  ResponseFailure,
  ResponseSuccess,
} from "../models/ApiResponse";
import { QuotesDatasource } from "./QuotesDatasource";
import { NewProcessedQuote } from "../../domain/models/NewProcessedQuote";
import { QuoteRequest } from "../../domain/models/QuoteRequest";
import { FirebaseEmail } from "../../domain/models/FirebaseEmail";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { NewConfirmedQuote } from "../../domain/models/NewConfirmedQuote";
import { PendingQuote } from "../../domain/models";
export enum QuoteType {
  Pending = "pending",
  Processed = "processed",
  Confirmed = "confirmed",
}

export class QuotesDatasourceImpl implements QuotesDatasource {
  pendingQuotesRef = collection(db, "pendingQuotes");
  processedQuotesRef = collection(db, "processedQuotes");
  confirmedQuotesRef = collection(db, "confirmedQuotes");
  mailRef = collection(db, "mail");
  functions = getFunctions(app, process.env.REACT_APP_CF_REGION);

  async createQuoteRequest(
    quoteRequest: QuoteRequest,
  ): Promise<ApiResponse<boolean>> {
    try {
      const addQuoteRequest = httpsCallable(
        this.functions,
        "createQuoteRequest",
      );
      await addQuoteRequest({
        name: quoteRequest.name,
        email: quoteRequest.email,
        phone: quoteRequest.phone,
        pIVA: quoteRequest.pIVA,
        codUnivoco: quoteRequest.codUnivoco,
        codFiscale: quoteRequest.codFiscale,
        address: quoteRequest.address,
        city: quoteRequest.city,
        cap: quoteRequest.cap,
        cart: quoteRequest.cart,
        date: quoteRequest.date,
        shippingAddress: quoteRequest.shippingAddress,
        shippingCity: quoteRequest.shippingCity,
        shippingCap: quoteRequest.shippingCap,
        deliveryDate: quoteRequest.deliveryDate,
        deliveryTime: quoteRequest.deliveryTime,
        eventDate: quoteRequest.eventDate,
        returnDate: quoteRequest.returnDate,
        returnTime: quoteRequest.returnTime,
        localPickup: quoteRequest.localPickup,
      });

      return new ResponseSuccess(true);
    } catch (error) {
      console.error("Error creating quote request:", error);
      return new ResponseFailure("Something went wrong");
    }
  }

  async getPendingQuotes(): Promise<
    ApiResponse<QuerySnapshot<DocumentData, DocumentData>>
  > {
    try {
      const quotesSnapshot = await getDocs(
        query(this.pendingQuotesRef, orderBy("date", "desc")),
      );
      return new ResponseSuccess(quotesSnapshot);
    } catch (e) {
      return new ResponseFailure("Something went wrong");
    }
  }

  async updateQuote(
    quote: PendingQuote,
    quoteType: QuoteType,
    firebaseEmail?: FirebaseEmail,
  ): Promise<ApiResponse<boolean>> {
    try {
      const updateQuoteFunction = httpsCallable(this.functions, "updateQuote");
      await updateQuoteFunction({
        quote,
        quoteType,
        firebaseEmail: firebaseEmail,
      });

      return new ResponseSuccess(true);
    } catch (error) {
      return new ResponseFailure("Something went wrong");
    }
  }

  async getProcessedQuotes(): Promise<
    ApiResponse<QuerySnapshot<DocumentData, DocumentData>>
  > {
    try {
      const quotesSnapshot = await getDocs(
        query(this.processedQuotesRef, orderBy("date", "desc")),
      );
      return new ResponseSuccess(quotesSnapshot);
    } catch (e) {
      return new ResponseFailure("Something went wrong");
    }
  }

  async getConfirmedQuotes(): Promise<
    ApiResponse<QuerySnapshot<DocumentData, DocumentData>>
  > {
    try {
      const quotesSnapshot = await getDocs(
        query(this.confirmedQuotesRef, orderBy("date", "desc")),
      );
      return new ResponseSuccess(quotesSnapshot);
    } catch (e) {
      console.error(e);
      return new ResponseFailure("Something went wrong:");
    }
  }

  async addProcessedQuote(
    pendingQuoteId: string,
    pendingQuote: NewProcessedQuote,
    firebaseEmail: FirebaseEmail,
  ): Promise<ApiResponse<boolean>> {
    try {
      const processQuote = httpsCallable(this.functions, "processPendingQuote");
      await processQuote({
        pendingQuoteId: pendingQuoteId,
        newProcessedQuote: pendingQuote,
        firebaseEmail: firebaseEmail,
      });

      return new ResponseSuccess(true);
    } catch (error) {
      console.error("Error adding processed quote:", error);
      return new ResponseFailure("Something went wrong");
    }
  }

  async addConfirmedQuote(
    processedQuoteId: string,
    processedQuote: NewConfirmedQuote,
  ): Promise<ApiResponse<boolean>> {
    try {
      const processQuote = httpsCallable(
        this.functions,
        "processProcessedQuote",
      );
      await processQuote({
        processedQuoteId: processedQuoteId,
        newConfirmedQuote: processedQuote,
      });
      return new ResponseSuccess(true);
    } catch (error) {
      console.error("Error adding confirmed quote:", error);
      return new ResponseFailure("Something went wrong");
    }
  }

  async uploadPDFQuote(
    clientEmail: string,
    pdf: File,
    fileName: string,
  ): Promise<ApiResponse<string>> {
    const storage = getStorage();

    try {
      const storageRef = ref(storage, `quotes/${clientEmail}/${fileName}`);

      await uploadBytes(storageRef, pdf);
      const uploadedPDFUrl = await getDownloadURL(storageRef);

      return new ResponseSuccess(uploadedPDFUrl);
    } catch (error) {
      return new ResponseFailure(
        "Errore durante il caricamento del preventivo in PDF.",
      );
    }
  }

  async deleteQuote(
    quoteId: string,
    quoteType: QuoteType,
  ): Promise<ApiResponse<boolean>> {
    try {
      const deleteQuote = httpsCallable(this.functions, "deleteQuote");
      const requestPayload = {
        quoteId,
        quoteType,
      };

      await deleteQuote(requestPayload);
      return new ResponseSuccess(true);
    } catch (error) {
      console.error(`Error deleting ${quoteType} quote:`, error);
      return new ResponseFailure("Something went wrong");
    }
  }
}
