import { QuoteType } from "../../data/datasource/QuotesDataSourceImpl";
import { PendingQuote } from "../models";
import { FirebaseEmail } from "../models/FirebaseEmail";
import { Success } from "../models/Result";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function updateQuote(
  quote: PendingQuote,
  quoteType: QuoteType,
  repository: QuotesRepository,
  firebaseEmail?: FirebaseEmail,
): Promise<boolean> {
  const result = await repository.updateQuote(quote, quoteType, firebaseEmail);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
