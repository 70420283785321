import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./main/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./presentation/components/ScrollToTop";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter
    future={{
      v7_startTransition: true,
      v7_relativeSplatPath: true,
    }}
  >
    <ScrollToTop />
    <App />
  </BrowserRouter>,
);

reportWebVitals();
