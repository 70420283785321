import { useState } from "react";
import { PendingQuote, ProcessedQuote } from "../../../domain/models";
import { useNavigate } from "react-router-dom";
import {
  getFormattedDate,
  getYearFromTimestamp,
} from "../../../utils/dateUtils";
import { ConfirmedQuote } from "../../../domain/models/ConfirmedQuote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

function TableQuotes({
  quotes,
  quoteType,
  onSort,
}: {
  quotes: PendingQuote[] | ProcessedQuote[] | ConfirmedQuote[];
  quoteType: string;
  onSort: (
    sortedQuotes: PendingQuote[] | ProcessedQuote[] | ConfirmedQuote[],
  ) => void;
}) {
  const [sortConfig, setSortConfig] = useState({
    key: "quoteNumber",
    direction: "desc",
  });
  const navigate = useNavigate();

  const sortQuotes = (
    quotes: PendingQuote[] | ProcessedQuote[] | ConfirmedQuote[],
    key: keyof PendingQuote | keyof ProcessedQuote | keyof ConfirmedQuote,
    direction: "asc" | "desc",
  ) => {
    const sortedQuotes = [...quotes];

    sortedQuotes.sort((a, b) => {
      const aValue = a[key as keyof PendingQuote];
      const bValue = b[key as keyof PendingQuote];

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });

    return sortedQuotes;
  };

  const handleSort = (column: string) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: column, direction });

    const sorted = sortQuotes(quotes, column as keyof PendingQuote, direction);
    onSort(sorted);
  };

  const navigateToQuote = (
    quote: PendingQuote | ProcessedQuote | ConfirmedQuote,
  ) => {
    navigate(`/dashboard/${quoteType}/${quote.id}`, { state: { quote } });
  };

  const direction: "asc" | "desc" =
    sortConfig.direction === "asc" || sortConfig.direction === "desc"
      ? sortConfig.direction
      : "asc";

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-palette-lighter">
        <tr>
          <th
            onClick={() => handleSort("quoteNumber")}
            className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
          >
            <div className="flex items-center justify-center space-x-1">
              <FontAwesomeIcon
                icon={faSort}
                className={
                  sortConfig.key === "quoteNumber"
                    ? "text-black"
                    : "text-gray-700"
                }
              />
              <span
                className={
                  sortConfig.key === "quoteNumber"
                    ? "text-black"
                    : "text-gray-700"
                }
              >
                #
              </span>
            </div>
          </th>
          <th
            className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
            onClick={() => handleSort("name")}
          >
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon
                icon={faSort}
                className={
                  sortConfig.key === "name" ? "text-black" : "text-gray-700"
                }
              />
              <span
                className={
                  sortConfig.key === "name"
                    ? "font-semibold text-black"
                    : "text-gray-700"
                }
              >
                Cliente
              </span>
            </div>
          </th>
          <th
            className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
            onClick={() => handleSort("address")}
          >
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon
                icon={faSort}
                className={
                  sortConfig.key === "address" ? "text-black" : "text-gray-700"
                }
              />
              <span
                className={
                  sortConfig.key === "address"
                    ? "font-semibold text-black"
                    : "text-gray-700"
                }
              >
                Indirizzo
              </span>
            </div>
          </th>
          <th
            className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
            onClick={() => handleSort("deliveryDate")}
          >
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon
                icon={faSort}
                className={
                  sortConfig.key === "deliveryDate"
                    ? "text-black"
                    : "text-gray-700"
                }
              />
              <span
                className={
                  sortConfig.key === "deliveryDate"
                    ? "font-semibold text-black"
                    : "text-gray-700"
                }
              >
                Data consegna
              </span>
            </div>
          </th>
          <th
            className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
            onClick={() => handleSort("eventDate")}
          >
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon
                icon={faSort}
                className={
                  sortConfig.key === "eventDate"
                    ? "text-black"
                    : "text-gray-700"
                }
              />
              <span
                className={
                  sortConfig.key === "eventDate"
                    ? "font-semibold text-black"
                    : "text-gray-700"
                }
              >
                Data evento
              </span>
            </div>
          </th>
          <th
            className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
            onClick={() => handleSort("returnDate")}
          >
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon
                icon={faSort}
                className={
                  sortConfig.key === "returnDate"
                    ? "text-black"
                    : "text-gray-700"
                }
              />
              <span
                className={
                  sortConfig.key === "returnDate"
                    ? "font-semibold text-black"
                    : "text-gray-700"
                }
              >
                Data Riconsegna
              </span>
            </div>
          </th>
          <th
            className="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
            onClick={() => handleSort("date")}
          >
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon
                icon={faSort}
                className={
                  sortConfig.key === "date" ? "text-black" : "text-gray-700"
                }
              />
              <span
                className={
                  sortConfig.key === "date"
                    ? "font-semibold text-black"
                    : "text-gray-700"
                }
              >
                Creato il
              </span>
            </div>
          </th>
        </tr>
      </thead>
      {quotes.length > 0 ? (
        <tbody className="divide-y divide-gray-100 bg-white">
          {sortQuotes(
            quotes,
            sortConfig.key as keyof PendingQuote,
            direction,
          ).map((quote, index) => (
            <tr
              key={quote.id}
              className={`cursor-pointer transition-all duration-300 hover:bg-palette-light ${
                index === 0 ? "rounded-t-xl" : ""
              } ${index === quotes.length - 1 ? "rounded-b-xl" : ""}`}
              onClick={() => navigateToQuote(quote)}
            >
              <td className="w-20 whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                {quote.quoteNumber
                  ? `${quote.quoteNumber}/${getYearFromTimestamp(quote.date)}`
                  : "N/A"}
              </td>
              <td className="whitespace-nowrap px-6 py-4">
                <p className="text-base font-semibold text-gray-900">
                  {quote.name}
                </p>
                <p className="text-sm text-gray-600">{quote.email}</p>
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-normal text-gray-900">
                {quote.address
                  ? `${quote.address}, ${quote.cap} ${quote.city}`
                  : "N/A"}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-normal text-gray-900">
                {quote.deliveryDate
                  ? getFormattedDate(quote.deliveryDate)
                  : "N/A"}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-normal text-gray-900">
                {quote.eventDate ? getFormattedDate(quote.eventDate) : "N/A"}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-sm font-normal text-gray-900">
                {quote.returnDate ? getFormattedDate(quote.returnDate) : "N/A"}
              </td>

              <td className="whitespace-nowrap px-6 py-4 text-sm font-normal text-gray-900">
                {getFormattedDate(quote.date) ?? "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tbody>
          <tr className="flex w-full justify-center">
            <td className="py-6 text-center text-gray-700">Nessun risultato</td>
          </tr>
        </tbody>
      )}
    </table>
  );
}

export default TableQuotes;
