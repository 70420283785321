import { PendingQuote, ProcessedQuote } from "../../../domain/models";
import {
  getFormattedDateWithHours,
  getFormattedDate,
} from "../../../utils/dateUtils";

function DetailQuoteData({ quote }: { quote: PendingQuote | ProcessedQuote }) {
  return (
    <table className="mt-4 w-full border-collapse overflow-hidden rounded-lg border outline outline-1 outline-gray-300">
      <tbody>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Data:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {getFormattedDateWithHours(quote.date)}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Cliente:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {quote.name}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Email:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {quote.email}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Telefono:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {quote.phone}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Partita IVA:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {quote.pIVA !== "" ? quote.pIVA.toUpperCase() : "Non specificata"}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Codice Univoco:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {quote.codUnivoco !== ""
              ? quote.codUnivoco.toUpperCase()
              : "Non specificato"}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Codice Fiscale:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {quote.codFiscale !== ""
              ? quote.codFiscale.toUpperCase()
              : "Non specificato"}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Indirizzo di fatturazione:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {quote.address === "" ||
            quote.address === null ||
            quote.address === undefined
              ? "Non specificato"
              : quote.address}{" "}
            {quote.city} {quote.cap}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Indirizzo di spedizione:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {quote.shippingAddress === ""
              ? "Non specificato"
              : quote.shippingAddress}{" "}
            {quote.shippingCity} {quote.shippingCap}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Data Consegna:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {getFormattedDate(quote.deliveryDate) ?? "Non specificata"}{" "}
            {quote.deliveryTime ?? ""}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Data Evento:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {getFormattedDate(quote.eventDate) ?? "Non specificata"}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Data Ritiro:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {getFormattedDate(quote.returnDate) ?? "Non specificata"}{" "}
            {quote.returnTime}
          </td>
        </tr>
        <tr>
          <td className="w-1/2 border border-gray-300 px-4 py-2 font-bold">
            Ritiro in loco:
          </td>
          <td className="w-1/2 border border-gray-300 px-4 py-2">
            {quote.localPickup ? "Si" : "No"}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default DetailQuoteData;
