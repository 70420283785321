import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import {
  getYearFromTimestamp,
  getFormattedDate,
} from "../../../utils/dateUtils";
import { PendingQuote, ProcessedQuote } from "../../../domain/models";
import logo from "../../../assets/images/logo-dap-rent.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  headerSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 20,
  },
  companyInfo: {
    flex: 1,
    paddingRight: 10,
  },
  companyName: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  companyData: {
    fontSize: 10,
    color: "#7d7d7d",
    marginBottom: 2,
  },
  li: { fontSize: 10, color: "#000", marginBottom: 10 },
  clientRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
  },
  clientInfoContainer: {
    borderWidth: 1,
    borderColor: "#D3C4B0",
    borderRadius: 10,
    borderStyle: "solid",
    padding: 5,
    marginBottom: 10,
    flex: 1,
    marginRight: 10,
  },
  eventInfoContainer: {
    borderWidth: 1,
    borderColor: "#D3C4B0",
    borderRadius: 10,
    borderStyle: "solid",
    padding: 5,
    marginBottom: 10,
    flex: 1,
  },
  tableHeader: {
    marginTop: 30,
    flexDirection: "row",
    backgroundColor: "#D3C4B0",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 5,
    alignItems: "center",
    textAlign: "center",
  },
  tableRow: {
    flexDirection: "row",
    marginTop: 2,
    paddingBottom: 2,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
    borderBottomStyle: "solid",
    alignItems: "center",
    textAlign: "center",
  },
  tableCol: {
    flex: 1,
    paddingRight: 10,
    fontSize: 12,
  },
  imageCol: {
    height: 40,
    objectFit: "contain",
  },
  textRight: {
    textAlign: "right",
  },
  summaryPriceSection: {
    borderWidth: 1,
    borderColor: "#D3C4B0",
    borderStyle: "solid",
    borderRadius: 10,
    marginTop: 50,
    marginLeft: "auto",
    width: "40%",
    textAlign: "right",
  },
  productTitle: {
    fontFamily: "SourceSansPro",
    fontWeight: 600,
    fontSize: 16,
  },
  productDescription: {
    fontFamily: "SourceSansPro",
    fontSize: 14,
  },
  title: {
    fontFamily: "SourceSansPro",
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  quoteNumber: {
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 20,
  },
  sectionTitle: {
    fontFamily: "SourceSansPro",
    fontSize: 18,
    fontWeight: "bold",
    marginVertical: 10,
  },
  productList: {
    fontSize: 12,
    marginVertical: 10,
  },
  productItem: {
    marginVertical: 10,
  },
  productImage: {
    width: 150,
    height: 150,
    marginTop: 30,
    marginBottom: 10,
    objectFit: "contain",
  },
  summary: {
    fontSize: 14,
    textAlign: "right",
    marginVertical: 5,
    fontFamily: "SourceSansPro",
    fontWeight: "bold",
  },
  summaryPriceContainer: {
    textAlign: "right",
    marginVertical: 5,
    marginRight: 5,
  },
  summaryLabel: {
    fontSize: 10,
    color: "#7d7d7d",
  },
  summaryValue: {
    fontSize: 12,
    color: "#000",
    fontWeight: "bold",
  },
  summaryLabelFinalOffer: {
    fontSize: 16,
    color: "#7d7d7d",
  },
  summaryValueFinalOffer: {
    fontSize: 16,
    color: "#000",
    fontWeight: "bold",
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: "#D3C4B0",
  },
  logoHeader: {
    height: 70,
    alignItems: "flex-start",
  },
  logo: {
    width: 100,
    marginVertical: 20,
    alignSelf: "center",
  },
  note: {
    fontSize: 12,
    fontStyle: "italic",
    color: "#444",
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    backgroundColor: "#f2f2f2",
    borderRadius: 5,
  },
  paymentInfo: {
    fontSize: 12,
    lineHeight: 1.5,
    color: "#333",
    marginBottom: 15,
  },
  boldText: {
    fontWeight: "bold",
    color: "#333",
  },
  highlightText: {
    fontWeight: "bold",
    color: "#5B4934",
  },
  listItem: {
    fontSize: 12,
    color: "#333",
    marginBottom: 5,
    marginLeft: 20,
  },
  bullet: {
    fontWeight: "bold",
    fontSize: 12,
    marginRight: 10,
    color: "#333",
  },
  thankYou: {
    fontSize: 12,
    lineHeight: 1.5,
    color: "#333",
    marginTop: 12,
  },
  nota: {
    fontSize: 12,
    lineHeight: 1.5,
    color: "#333",
  },
});

const contractItems = [
  "Contratto di noleggio. Il contratto è un accordo tra la scrivente Società EVENTI & SAPORI srl, quale Fornitore del materiale come indicato nell'Ordine di noleggio, e la persona fisica e/o giuridica ivi indicata, quale Committente del medesimo. Al momento dell'ordine il Committente dovrà comunicare l'eventuale presenza di zone pedonali e/o traffico limitato (ZTL) ed i relativi permessi necessari per effettuare l'accesso alla zona di scarico. Dovranno inoltre essere comunicate eventuali difficoltà d'accesso sia per l'entrata dei mezzi che per lo scarico del materiale nel luogo di destinazione. In assenza di tali comunicazioni, qualora dovessero presentarsi le circostanze sopracitate, lo scarico verrà effettuato nel luogo più vicino raggiungibile dai nostri automezzi a quello indicato per evitare infrazioni e/o danneggiamenti a mezzi e strutture.",
  "Requisiti minimi di noleggio. che variano a seconda della distanza, del numero di mezzi utilizzati e del personale necessario per lo scarico. Lo scarico verrà effettuato dal nostro personale al piano terra del luogo della consegna più vicino raggiungibile con i nostri automezzi; eventuali diverse situazioni dovranno essere comunicate al momento dell'ordine al fine di valutare eventuali servizi di facchinaggio. Il materiale verrà consegnato pulito, impacchettato e sterilizzato, negli appositi contenitori.",
  "Consegna e scarico del materiale . Il materiale può essere consegnato a destinazione indicata dal destinatario con spese di viaggio nel Documento di trasporto. Il Fornitore al fine di garantire il materiale noleggiato da eventuali rotture ed ammanchi richiederà la garanzia di una carta di credito.",
  "Garanzia e responsabilità del Committente. Dal momento della consegna il Committente si farà garante del materiale consegnato indicato nel documento di trasporto. Il fornitore al fine di garantire il materiale noleggiato da eventuali rotture ed ammacchi richiederà la garanzia di una carta di credito.",
  "Riconsegna del materiale. La riconsegna avverrà nel luogo e con le tempistiche indicate al momento dell'ordine. A tal proposito si precisa che le scheggiature del materiale frangibile sono da intendersi come rotture. Il materiale verrà poi ritirato sporco. Il materiale dovrà essere riconsegnato negli appositi contenitori ognuno dei quali dovrà contenere il numero esatto di pezzi riportato in ogni contenitore. Tutto il materiale dovrà inoltre essere ricollocato nella parte più comoda per il ritiro. Il lavaggio del materiale è da intendersi a nostro carico, ed il conteggio del materiale minuto verrà eseguito nei nostri magazzini una volta pulito il materiale. Eventuale materiale mancante o danneggiato sarà a carico del committente e quantificato al costo di listino rotture e mancanze.  Il Commitente è tenuto a riconsegnare il materiale noleggiato nella data e luogo concordata al momento dell'ordine. Se il Committente ha necessità di riconsegnare il materiale oltre l'orario/data stabiliti sarà tenuto a richiedere per iscritto il prolungamento. Eventuale prolungamento del noleggio non concordati sarà addebitato un ulteriore noleggio in base al tempo trattenuto.",
  "Contestazioni Eventuali. contestazioni sul materiale consegnato dovranno essere evidenziate lo stesso giorno della consegna ed in tal caso verrà effettuata la sostituzione senza alcun costo aggiuntivo. Per le contestazioni pervenute nei giorni successivi verranno addebitate le spese di consegna.",
  "Pagamento. Il pagamento integrale dell'ordine dovrà esser effettuato dal Committente prima del ricevimento del materiale noleggiato con le modalità definite al momento dell’ordine",
  "Pre-autorizzazione e garanzia. Alla sottoscrizione del contratto di noleggio il Cliente concede al Fornitore il diritto di bloccare un importo a garanzia sulla propria carta di credito in proporzione al valore totale dell'ordine, vincolando così il totale importo sulla carta di credito per garantire la presenza di fondi sufficienti per garantire eventuali ammanchi o rotture del materiale noleggiato. Se il Cliente pagherà con un altra carta, saranno necessari fino ad un massimo di 28 giorni prima che la banca restituisca l'importo precedentemente 'bloccato'. Sottoscrivendo il contratto di noleggio, il Cliente autorizza la Società EVENTI & SAPORI Srl ad addebitare sulla carta di pagamento tutti i costi generati da eventuali rotture e/o mancanze e/o danneggiamenti del materiale noleggiato.",
  "Caparra confirmatoria. Il Committente al momento dell'ordine si impegna a corrispondere al fornitore una somma a titolo di caparra confirmatoria pari al 50% dell'ordine, pena la risoluzione dell'accordo, per ordini di importo ridotto può essere richiesto anche il 100%.",
  "Avvertenza. Si precisa che la disponibilità e la consegna del materiale non sono garantite fino all'accettazione del preventivo/offerta inviato dal Fornitore ed alla conseguente ricezione del contratto debitamente firmato in ogni sua parte dal Committente. Solo alla RICEZIONE del contratto firmato verranno verificate le effettive disponibilità del materiale richiesto e la possibilità di effettuare la consegna. Si ricorda che il preventivo/offerta è puramente indicativo e non comporta alcun vincolo d'opzione sul materiale, nè alcun obbligo a carico del Fornitore e del Cliente.",
  "Clausola Risolutiva. La violazione anche di una sola delle disposizioni degli articoli 2, 3, 4, 5, 6, 8 e 9 legittimerà il Fornitore alla effettuare risoluzione del contratto ai sensi dell'art. 1456 del codice civile e al risarcimento dei danni.",
  "Clausola Pandemica. Qualora in caso o a causa della diffusione del Covid-19 o altre variazioni dello stesso fosse impossibile viaggiare e/o effettuare il noleggio nella data concordata, Eventi & Sapori srl darà la possibilità ai propri clienti di utilizzare il deposito cauzionale versato per ulteriore ordine che dovrà avvenire entro e non noltre i 12 mesi dalla data inizialmente concordata, pena la perdita della somma corrisposta a titolo di caparra confirmatoria.",
  "Caparra Penitenziale. In caso di cancellazione, recesso, risoluzione e/o atto e/o dichiarazione interruttiva dell'ordine di noleggio comunicata al Cliente il Fornitore tratterrà l'intera caparra data. In caso di cancellazione, recesso , risoluzione e/o atto e/o dichiarazione interruttiva dell'ordine a meno di 7 giorni dalla consegna del materiale il Fornitore avrà diritto al pagamento dell'intero corrispettivo di noleggio.",
  "Variazioni dell'ordine. Nel caso in cui il Cliente dovesse variare la data dell'ordine, il Fornitore si avvale della facoltà di controllare la disponibilità del materiale e di adeguare i prezzi degli articoli ai listini in corso.",
  "Legge applicabile e Foro Competente Il contratto di noleggio sottoscritto tra le parti sarà disciplinato dalla legge Italiana e dal Codice Civile. Per ogni controversia derivante dai rapporti regolati da Ordine e Condizioni generali di noleggio, è competente in via esclusiva il Foro di Torino",
  "Tutela della riservatezza e trattamento dei dati personali. Ai sensi dell'art.12 della Leg. 196/2003 (Testo Unico sulla Privacy e successive modifiche ed integrazioni GDPR Regolamento Generale sulla Protezione dei Dati - Regolamento 2016/679), i dati personali che riguardano il Committente saranno trattati dalla Società EVENTI & SAPORI Srl per l'adempimento dell'ordine di noleggio e non verranno comunicati a terzi. I dati possono esser utilizzati per informare sulle attività della Società. In relazione ai dati forniti il conferente può esercitare i diritti espressi all'art. 6 della Leg. 196/2003 (Testo Unico sulla Privacy e successive modifiche e integrazioni GDPR Regolamento Generale sulla Protezione dei Dati - Regolamento 2016/679): conferma dell'esistenza dei dati , origine finalità aggiornamenti, cancellazione, diritto di opposizione. Per esercitare tali diritti dovrà fare riferimento al responsabile del trattamento dei dati della Società EVENTI & SAPORI Srl. Le Parti si autorizzano reciprocamente a comunicare a terzi i rispettivi dati personali in relazione agli obblighi connessi al contratto di noleggio (ex D.lgs 196/2003).",
];

const QuoteEmailDocument = ({
  quote,
  productsTotalPrice,
  taxes,
  shippingCost,
  discountValue,
  total,
}: {
  quote: PendingQuote | ProcessedQuote;
  productsTotalPrice: number;
  taxes: number;
  shippingCost: number;
  discountValue: number;
  total: number;
}) => {
  Font.register({
    family: "SourceSansPro",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf",
      }, // font-style: normal, font-weight: normal
      {
        src: "https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf",
        fontWeight: 600,
      },
    ],
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerSection}>
            <View style={styles.companyInfo}>
              <Text style={styles.companyName}>Dap Rent</Text>
              <Text style={styles.companyData}>P.IVA: 03740990043</Text>
              <Text style={styles.companyData}>
                Via Macallè, 33/A, 12045 Fossano CN
              </Text>
              <Text style={styles.companyData}>
                daprent.preventivi@gmail.com | +39 347 468 9592
              </Text>
            </View>
            <Image src={logo} style={styles.logoHeader} />
          </View>

          <Text style={styles.quoteNumber}>
            Preventivo n° {quote.quoteNumber}/{getYearFromTimestamp(quote.date)}{" "}
            del {getFormattedDate(quote.date)}
          </Text>
          <View style={styles.clientRow}>
            <View style={styles.clientInfoContainer}>
              <Text style={styles.companyName}>Cliente</Text>
              <Text style={styles.companyData}>{quote.name}</Text>
              <Text style={styles.companyData}>
                {quote.address?.trim()
                  ? `${quote.address}, ${quote.cap}, ${quote.city}`
                  : "Indirizzo non disponibile"}
              </Text>
              <Text style={styles.companyData}>{quote.phone}</Text>
              <Text style={styles.companyData}>{quote.email}</Text>
              {quote.pIVA === "" ? (
                <>
                  <Text style={styles.companyData}>{quote.pIVA}</Text>
                  <Text style={styles.companyData}>{quote.codUnivoco}</Text>
                </>
              ) : (
                <Text style={styles.companyData}>{quote.codFiscale}</Text>
              )}
            </View>
            <View style={styles.eventInfoContainer}>
              <Text style={styles.companyName}>Evento</Text>
              <Text style={styles.companyData}>
                Spedizione:{" "}
                {quote.localPickup
                  ? "Ritiro in loco"
                  : quote.shippingAddress === ""
                    ? "non specificata"
                    : quote.shippingAddress}
              </Text>
              <Text style={styles.companyData}>
                Data spedizione:{" "}
                {getFormattedDate(quote.deliveryDate) ?? "non specificata"}{" "}
                {quote.deliveryTime}
              </Text>
              <Text style={styles.companyData}>
                Data evento:{" "}
                {getFormattedDate(quote.eventDate) ?? "non specificata"}
              </Text>
              <Text style={styles.companyData}>
                Data riconsegna:{" "}
                {getFormattedDate(quote.returnDate) ?? "non specificata"}{" "}
                {quote.returnTime}
              </Text>
            </View>
          </View>

          <View style={styles.tableHeader}>
            <Text style={[styles.tableCol]}>Immagine</Text>
            <Text style={styles.tableCol}>Prodotto</Text>
            <Text style={styles.tableCol}>Quantità</Text>
            <Text style={[styles.tableCol, styles.textRight]}>Prezzo</Text>
            <Text style={[styles.tableCol, styles.textRight]}>Importo</Text>
          </View>

          {quote.cart.map((product) => (
            <View style={styles.tableRow} key={product.id}>
              <Image
                style={[styles.tableCol, styles.imageCol]}
                src={product.image}
              />
              <Text style={styles.tableCol}>{product.title}</Text>
              <Text style={styles.tableCol}>{product.quantity}</Text>
              <Text style={[styles.tableCol, styles.textRight]}>
                {product.price.toFixed(2).replace(".", ",")} €
              </Text>
              <Text style={[styles.tableCol, styles.textRight]}>
                {(product.price * product.quantity)
                  .toFixed(2)
                  .replace(".", ",")}{" "}
                €
              </Text>
            </View>
          ))}

          <View style={styles.summaryPriceSection}>
            <Text style={styles.summaryPriceContainer}>
              <Text style={styles.summaryLabel}>Subtotale: </Text>
              <Text style={styles.summaryValue}>
                {productsTotalPrice.toFixed(2).replace(".", ",")} €
              </Text>
            </Text>

            <Text style={styles.summaryPriceContainer}>
              <Text style={styles.summaryLabel}>Costi di spedizione: </Text>
              <Text style={styles.summaryValue}>
                {shippingCost.toFixed(2).replace(".", ",")} €
              </Text>
            </Text>
            <Text style={styles.summaryPriceContainer}>
              <Text style={styles.summaryLabel}>Totale IVA: </Text>
              <Text style={styles.summaryValue}>
                {taxes.toFixed(2).replace(".", ",")} €
              </Text>
            </Text>
            <Text style={styles.summaryPriceContainer}>
              <Text style={styles.summaryLabel}>Sconto: </Text>
              <Text style={styles.summaryValue}>
                {discountValue.toFixed(2).replace(".", ",")} €
              </Text>
            </Text>
            <View style={styles.separator} />

            <Text style={styles.summaryPriceContainer}>
              <Text style={styles.summaryLabelFinalOffer}>
                Offerta cliente:{" "}
              </Text>
              <Text style={styles.summaryValueFinalOffer}>
                {total.toFixed(2).replace(".", ",")} €
              </Text>
            </Text>
          </View>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Note e informazioni sul pagamento</Text>
          {quote.text && <Text style={styles.note}>{quote.text}</Text>}{" "}
          <Text style={styles.paymentInfo}>
            <Text style={styles.boldText}>
              Se le condizioni proposte sono di suo gradimento, può procedere
              con l'acquisto effettuando il pagamento tramite bonifico bancario:
            </Text>
          </Text>
          <View>
            <Text style={[styles.listItem]}>
              <Text style={styles.bullet}>•</Text> IBAN:
              IT91F0883346322000070102924
            </Text>
            <Text style={[styles.listItem]}>
              <Text style={styles.bullet}>•</Text> Intestatario: Eventi & Sapori
              Srl
            </Text>
            <Text style={[styles.listItem]}>
              <Text style={styles.bullet}>•</Text> Causale: 35/2025 - 13/03/2025
            </Text>
          </View>
          <Text style={styles.thankYou}>
            Grazie per aver utilizzato il nostro servizio. Per ulteriori
            informazioni non esiti a contattarci telefonicamente al numero +39
            347 468 9592 o tramite email rispondendo a questo preventivo o
            inviandone una nuova all'indirizzo daprent.preventivi@gmail.com
          </Text>
          <Image src={logo} style={styles.logo} />
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Condizioni Generali di Noleggio</Text>

          {contractItems.map((item, index) => (
            <Text style={styles.li} key={index}>
              {index + 1}) {item}
            </Text>
          ))}

          <Text style={styles.paymentInfo}>Luogo e data,</Text>
          <Text style={styles.paymentInfo}>__________________________</Text>
          <Text style={styles.paymentInfo}>Il Committente</Text>
          <Text style={styles.paymentInfo}>___________________________</Text>
          <Text style={styles.paymentInfo}>
            Ai sensi e per effetti degli artt. 1341 e 1342 c.c., il Committente
            dichiara di aver preso specifica visione e di approvare
            specificamente le seguenti clausole: Articolo 2 - Requisiti minimi
            di noleggio; Articolo 3 - Consegna e scarico del materiale; Articolo
            4 - Garanzia e responsabilità del Committente; Articolo 5 -
            Riconsegna del materiale; Articolo 6 - Tardata riconsegna del
            materiale; Articolo 7 - Cotestazioni; Articolo 8 - Pagamenti;
            Articolo 9 - Caparra confirmatoria; Articolo 10 - Avvertenza;
            Articolo 11 - Clausola risolutiva; Articolo 12 - Cancellazioni;
            Articolo 13 - Variazioni; Articolo 14 - Legge applicapile e foro
            competente
          </Text>
          <Text style={styles.paymentInfo}>
            Letto, confermato e sottoscritto
          </Text>
          <Text style={styles.paymentInfo}>___________________________</Text>
        </View>
      </Page>
    </Document>
  );
};

export default QuoteEmailDocument;
