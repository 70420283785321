import { ConfirmedQuote } from "../models/ConfirmedQuote";
import { Success } from "../models/Result";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function getConfirmedQuotes(
  repository: QuotesRepository,
): Promise<ConfirmedQuote[]> {
  const result = await repository.getConfirmedQuotes();
  if (result instanceof Success) {
    return result.value;
  } else {
    return [];
  }
}
