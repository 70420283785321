import { FooterComponent } from "../components/client/FooterComponent";
import { AuthRepository } from "../../domain/repository/AuthRepository";
import { useEffect, useState } from "react";
import { useAuthModelController } from "../hook/useAuthModelController";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { useNavigate } from "react-router-dom";
import SignInForm, {
  SignInFormData,
} from "../components/admin/SignInFormComponent";
import ForgotPasswordFormComponent from "../components/admin/ForgotPasswordFormComponent";
import { useAuthStatus } from "../hook/useAuthStatus";
import loadingAnimation from "../../assets/lotties/loading.json";
import AnimationComponent from "../components/AnimationComponent";

export function SignInView({
  authRepository,
}: {
  authRepository: AuthRepository;
}) {
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [formData, setFormData] = useState<SignInFormData>({
    email: "",
    password: "",
  });
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>("");
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const [showLoggedMessage, setShowLoggedMessage] = useState<boolean>(false);

  const handleFormDataChange = (formData: SignInFormData) => {
    setFormData(formData);
  };

  const handleForgotPasswordEmailChange = (email: string) => {
    setForgotPasswordEmail(email);
  };

  const { handleSignIn, handleRestorePassword } =
    useAuthModelController(authRepository);

  const { loggedIn } = useAuthStatus();

  useEffect(() => {
    if (loggedIn) {
      setShowLoggedMessage(true);
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    }
  }, [loggedIn, navigate]);

  const signIn = async () => {
    try {
      const result = await handleSignIn(formData.email, formData.password);
      if (result) {
        setLoginSuccess(true);
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } else {
        setLoginSuccess(false);
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
        );
      }
    } catch (error) {
      setLoginSuccess(false);
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
      );
    }
  };

  const restorePassword = async () => {
    try {
      const result = await handleRestorePassword(forgotPasswordEmail);
      if (result) {
        showSuccessToast("Email inviata");
        setForgotPassword(false);
      } else {
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
        );
      }
    } catch (error) {
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
      );
    }
  };

  return showLoggedMessage && !loginSuccess ? (
    <div className="flex h-screen items-center justify-center">
      <AnimationComponent
        animation={loadingAnimation}
        message="Sei già autenticato"
      />
    </div>
  ) : loginSuccess ? (
    <div className="flex h-screen items-center justify-center">
      <AnimationComponent
        animation={loadingAnimation}
        message="Login in corso"
      />
    </div>
  ) : (
    <div className="min-h-full w-full">
      {forgotPassword ? (
        <ForgotPasswordFormComponent
          onCancel={() => setForgotPassword(false)}
          onEmailChange={handleForgotPasswordEmailChange}
          restorePassword={restorePassword}
        />
      ) : (
        <SignInForm
          onForgotPassword={() => setForgotPassword(true)}
          signIn={signIn}
          onFormDataChange={handleFormDataChange}
        />
      )}

      <FooterComponent />
    </div>
  );
}
