import { CatalogueView } from "./CatalogueView";
import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { AuthRepository } from "../../domain/repository/AuthRepository";

export function DashboardView({
  authRepository,
  productRepository,
  quotesRepository,
}: {
  authRepository: AuthRepository;
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  return (
    <div className="min-h-full w-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <CatalogueView
        productRepository={productRepository}
        quotesRepository={quotesRepository}
      />

      <FooterComponent />
    </div>
  );
}
