import { useEffect, useState } from "react";
import { HeaderComponent } from "../components/client/HeaderComponent";
import { ProductDetailsComponent } from "../components/client/ProductDetailsComponent";
import { ProductImageComponent } from "../components/client/ProductImageComponent";
import { ProductSuggestions } from "../components/client/ProductSuggestions";
import { useClientModelController } from "../hook/useClientModelController";
import { ProductData } from "../../domain/models";
import { useNavigate, useParams } from "react-router-dom";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { BackToProductButton } from "../components/client/BackToProductButton";
import { getCategoryFromFullPath } from "../../utils/productUtils";

export function ProductPage({
  productsRepository,
  quotesRepository,
}: {
  productsRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const { handleGetAllProducts } = useClientModelController(
    productsRepository,
    quotesRepository,
  );
  const navigate = useNavigate();

  const { productId } = useParams();
  const [product, setProduct] = useState<ProductData | null>(null);
  const [productsFromSet, setProductsFromSet] = useState<ProductData[] | null>(
    null,
  );
  const [isSet, setIsSet] = useState<boolean>(false);
  useEffect(() => {
    loadProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const loadProduct = async () => {
    const products: ProductData[] = await handleGetAllProducts();
    const currentProduct =
      products.find((item) => item.id === productId) ?? null;
    setProduct(currentProduct);
    if (currentProduct === null) {
      const currentProductsFromSet =
        products.filter(
          (item) =>
            getCategoryFromFullPath(item.category).toLowerCase() ===
            productId?.toLowerCase(),
        ) ?? null;

      if (
        currentProductsFromSet !== null &&
        currentProductsFromSet.length > 0
      ) {
        setIsSet(true);
        setProductsFromSet(currentProductsFromSet);
      }
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="min-h-full w-full">
      <HeaderComponent
        productsRepository={productsRepository}
        quotesRepository={quotesRepository}
      />
      {isSet ? (
        <>
          <div className="mx-auto flex w-11/12 max-w-2xl flex-col items-center justify-center py-6 md:flex-row md:items-start md:space-x-8 md:space-y-0 lg:space-x-24">
            <BackToProductButton goBack={goBack} />
          </div>
          {productsFromSet?.map((product) => (
            <div className="mx-auto flex w-11/12 max-w-6xl flex-col items-center justify-center space-y-8 py-24 md:flex-row md:items-start md:space-x-8 md:space-y-0 lg:space-x-24">
              {product && (
                <>
                  <ProductImageComponent product={product} />

                  <ProductDetailsComponent
                    productData={{
                      id: product.id,
                      category: product?.category,
                      title: product.title,
                      description: product.description,
                      price: product.price,
                      discount: product.discount,
                      images: product.images,
                    }}
                    showBackButton={false}
                  />
                </>
              )}
            </div>
          ))}
        </>
      ) : (
        <div className="mx-auto flex w-11/12 max-w-6xl flex-col items-center justify-center space-y-8 py-24 md:flex-row md:items-start md:space-x-8 md:space-y-0 lg:space-x-24">
          {product && <ProductImageComponent product={product} />}
          {product && (
            <ProductDetailsComponent
              productData={{
                id: product.id,
                category: product?.category,
                title: product.title,
                description: product.description,
                price: product.price,
                discount: product.discount,
                images: product.images,
              }}
              showBackButton={true}
            />
          )}
        </div>
      )}

      <ProductSuggestions
        productsRepository={productsRepository}
        quotesRepository={quotesRepository}
      />
    </div>
  );
}
