import { NewConfirmedQuote } from "../models/NewConfirmedQuote";
import { Success } from "../models/Result";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function processProcessedQuote(
  processedQuoteId: string,
  newConfirmedQuote: NewConfirmedQuote,
  repository: QuotesRepository,
): Promise<boolean> {
  const result = await repository.processProcessedQuote(
    processedQuoteId,
    newConfirmedQuote,
  );
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
