import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import AddProductDialogComponent from "../components/admin/AddProductDialogComponent";
import { ProductData } from "../../domain/models";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { useAdminModelController } from "../hook/useAdminModelController";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";

import chair_icon from "../../assets/images/chair.png";
import table_icon from "../../assets/images/table.png";
import mise_en_place_icon from "../../assets/images/mise_en_place.png";
import garden_icon from "../../assets/images/garden.png";
import cutlery from "../../assets/images/cutlery.png";
import glass from "../../assets/images/glass.png";
import tablecloth from "../../assets/images/tablecloth.png";

import noProducts from "../../assets/lotties/no_products.json";
import loadingAnimation from "../../assets/lotties/loading.json";
import AnimationComponent from "../components/AnimationComponent";
import emptySetAnimation from "../../assets/lotties/empty_set.json";

import ProductCatalogueItem from "../components/admin/ProductCatalogueItem";
import { useClientModelController } from "../hook/useClientModelController";
import { Set } from "../../domain/models/Set";
import SubCategoryItem from "../components/admin/SubCategoryItem";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import SetCatalogueItem from "../components/admin/SetCatalogueItem";
import AddSetDialogComponent from "../components/admin/AddSetDialogComponent";
import {
  findSubcategories,
  getCategoryFromFullPath,
} from "../../utils/productUtils";

const sortOptions = [
  { name: "Prezzo: Basso-Alto", href: "#", current: false },
  { name: "Prezzo: Algo-Basso", href: "#", current: false },
];

const categories = [
  {
    name: "Sedie",
    imageSrc: chair_icon,
    imageAlt: "Sedie",
    href: "#",
  },
  {
    name: "Tavoli",
    imageSrc: table_icon,
    imageAlt: "Tavoli",
    href: "#",
  },
  {
    name: "Piatti e sottopiatti",
    imageSrc: mise_en_place_icon,
    imageAlt: "Piatti e sottopiatti",
    href: "#",
  },
  {
    name: "Posate",
    imageSrc: cutlery,
    imageAlt: "Posate",
    href: "#",
  },
  {
    name: "Bicchieri",
    imageSrc: glass,
    imageAlt: "Bicchieri",
    href: "#",
  },
  {
    name: "Arredo giardino",
    imageSrc: garden_icon,
    imageAlt: "Arredo giardino",
    href: "#",
  },
  {
    name: "Tovagliato",
    imageSrc: tablecloth,
    imageAlt: "Tovagliato",
    href: "#",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function CatalogueView({
  productRepository,
  quotesRepository,
}: {
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();

  const [category, setCategory] = useState<string>(
    sessionStorage.getItem("category") || "Sedie",
  );
  const [subCategory, setSubCategory] = useState<string>("");
  const [subCategories, setSubCategories] = useState<string[] | null>(null);
  const [sets, setSets] = useState<Set[] | null>(null);

  const [addProductDialogeOpen, setAddProductDialogOpen] =
    useState<boolean>(false);

  const [addSetDialogeOpen, setAddSetDialogOpen] = useState<boolean>(false);

  const [products, setProducts] = useState<ProductData[]>([]);
  const [categorySets, setCategorySets] = useState<Set[] | null | undefined>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [productsBySubCategory, setProductsBySubCategory] = useState<
    ProductData[] | null
  >(null);
  const [displaySubCategoryItems, setDisplaySubCategoryItems] =
    useState<boolean>(false);

  const { handleGetAllProducts } = useAdminModelController(
    productRepository,
    quotesRepository,
  );

  const { handleGetAllSets } = useClientModelController(
    productRepository,
    quotesRepository,
  );

  useEffect(() => {
    const savedCategory = sessionStorage.getItem("category");
    if (savedCategory) {
      setCategory(savedCategory);
      setSubCategories(findSubcategories(category));
    } else {
      setCategory("Sedie");
      setSubCategories(findSubcategories(category));
    }

    setLoading(true);
    loadProducts();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    sessionStorage.setItem("category", "Sedie");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProducts = async () => {
    const allProducts: ProductData[] = await handleGetAllProducts();
    setProducts(allProducts);
    const setsResult = await handleGetAllSets();
    setSets(setsResult);
    filterCategory(category ?? categories[0].name, false, setsResult!);
    if (subCategory && subCategory !== "") {
      filterSubcategoryItems(subCategory, setsResult!);
    }
  };

  const filteredProducts = products.filter((product) => {
    const productCategoryLastPart = getCategoryFromFullPath(product.category);
    return productCategoryLastPart === category.toLowerCase();
  });

  const filterCategorySets = (_cat: string, _sets: Set[]) => {
    let setInCategory = _sets.filter((product) => {
      const parts = product.category.split("\\");
      const set = parts.pop();
      return set!.toLowerCase() === _cat.toLowerCase();
    });
    setCategorySets(setInCategory);
  };

  const filterSubcategoryItems = (subCat: string, _sets?: Set[]) => {
    setSubCategory(subCat);
    let filteredProductsBySubCategory = products.filter((product) => {
      const parts = product.category.split("\\");
      const subCategory = parts[1] || "";
      const set = parts[2] || "";
      return subCategory.toLowerCase() === subCat.toLowerCase() && set === "";
    });

    setProductsBySubCategory(filteredProductsBySubCategory);
    let filteredSetBySubCategory = _sets?.filter(
      (set) => set.subcategory === subCat,
    );
    if (filteredSetBySubCategory) {
      setCategorySets(filteredSetBySubCategory!);
    }
    setDisplaySubCategoryItems(true);
  };

  const sortedProducts = filteredProducts.sort((a, b) => {
    const nameA = a.title.toLowerCase();
    const nameB = b.title.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const filterCategory = (
    categoryName: string,
    forceRefresh: boolean,
    _sets?: Set[],
  ) => {
    setCategory(categoryName);
    setSubCategory("");
    setCategorySets(null);
    let category = categoryName.toLowerCase();
    setSubCategories(findSubcategories(category!));
    if (
      _sets !== null &&
      _sets !== undefined &&
      (!subCategory || subCategory !== "" || forceRefresh)
    ) {
      filterCategorySets(category!, _sets!);
    }
  };

  const navigateToProduct = (productId: string, set?: Set) => {
    sessionStorage.setItem("category", category);
    if (subCategory !== "" && subCategory !== null) {
      navigate(
        `/dashboard/${category}/${subCategory ?? "product"}/${productId}`,
        { state: { set: set } },
      );
    } else {
      navigate(`/dashboard/${category}/${productId}`, { state: { set: set } });
    }
  };

  function getCategoryIndexByName(name: string): number {
    return categories.findIndex((category) => category.name === name);
  }

  return (
    <div>
      <main className="mx-auto w-full px-4 sm:px-6 lg:px-8">
        <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-16">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Catalogo prodotti
          </h1>

          <div className="flex items-center">
            <Menu as="div" className="relative inline-block text-left">
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <a
                            href={option.href}
                            className={classNames(
                              option.current
                                ? "font-medium text-gray-900"
                                : "text-gray-500",
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm",
                            )}
                          >
                            {option.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <section aria-labelledby="products-heading" className="pb-24 pt-6">
          <h2 id="products-heading" className="sr-only">
            Prodotti
          </h2>

          <div className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:grid-cols-5">
            {/* Filters */}
            <div>
              <h3 className="sr-only">Categorie</h3>
              <ul className="space-y-4 border-b border-gray-200 pb-6 text-sm text-gray-900">
                {categories.map((currentCategory) => (
                  <li
                    key={currentCategory.name}
                    onClick={() => {
                      filterCategory(currentCategory.name, true, sets ?? []);
                      setDisplaySubCategoryItems(false);
                    }}
                    className={classNames(
                      "flex cursor-pointer items-center space-x-2 px-4 py-2",
                      getCategoryFromFullPath(category.toLowerCase()) ===
                        currentCategory.name.toLowerCase()
                        ? "rounded-lg bg-palette-primary text-white"
                        : "rounded-lg hover:bg-palette-lighter",
                    )}
                  >
                    <img
                      src={currentCategory.imageSrc}
                      alt={currentCategory.imageAlt}
                      className="h-6 w-6"
                      style={{
                        filter:
                          getCategoryFromFullPath(category.toLowerCase()) ===
                          currentCategory.name.toLowerCase()
                            ? "invert(1)"
                            : "",
                      }}
                    />
                    <p
                      className={classNames(
                        "cursor-pointer",
                        getCategoryFromFullPath(category.toLowerCase()) ===
                          currentCategory.name.toLowerCase()
                          ? "font-bold"
                          : "font-normal",
                      )}
                    >
                      {currentCategory.name}
                    </p>
                  </li>
                ))}
              </ul>
            </div>

            {/* Product grid */}
            {loading ? (
              <div className="lg:col-span-4">
                <AnimationComponent
                  animation={loadingAnimation}
                  message="Caricamento prodotti in corso"
                />
              </div>
            ) : (
              <div className="lg:col-span-4">
                {displaySubCategoryItems ? (
                  <button
                    onClick={() => {
                      setSubCategory("");
                      setProductsBySubCategory(null);
                      setDisplaySubCategoryItems(false);
                    }}
                    className="mb-6 flex w-fit items-center justify-center space-x-2 text-center"
                  >
                    <ArrowLongLeftIcon className="h-5 w-5" />
                    <p className="text-lg">Torna indietro</p>
                  </button>
                ) : null}
                {(subCategories === null || displaySubCategoryItems) && (
                  <>
                    <div className="mb-6 flex justify-between">
                      <p className="text-2xl font-bold">Set</p>
                      <button
                        onClick={() => setAddSetDialogOpen(true)}
                        className="flex w-fit items-center justify-center rounded-full border border-transparent bg-palette-primary px-4 py-2 text-sm font-medium text-white hover:bg-palette-dark focus:bg-palette-dark focus:outline-none focus:ring-2 focus:ring-offset-2"
                      >
                        <PlusCircleIcon
                          className="mr-1 h-6 w-6"
                          aria-hidden="true"
                        />
                        Aggiungi Set
                      </button>
                    </div>

                    {categorySets && categorySets.length > 0 ? (
                      <div className="mb-12 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                        {categorySets?.map((set) => (
                          <SetCatalogueItem
                            key={set.name}
                            productName={set.name}
                            productImage={set.image}
                            navigateToProduct={(productId) =>
                              navigateToProduct(productId, set)
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      <AnimationComponent
                        animation={emptySetAnimation}
                        message="Non hai ancora aggiunto dei set"
                      />
                    )}
                  </>
                )}

                <div className="mb-6 flex justify-between">
                  <p className="text-2xl font-bold">
                    {subCategories === null || displaySubCategoryItems
                      ? "Prodotti"
                      : "Sottocategorie"}
                  </p>
                  <button
                    onClick={() => setAddProductDialogOpen(true)}
                    className="flex w-fit items-center justify-center rounded-full border border-transparent bg-palette-primary px-4 py-2 text-sm font-medium text-white hover:bg-palette-dark focus:bg-palette-dark focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <PlusCircleIcon
                      className="mr-1 h-6 w-6"
                      aria-hidden="true"
                    />
                    Aggiungi Prodotto
                  </button>
                </div>
                {subCategories === null ? (
                  sortedProducts && sortedProducts.length > 0 ? (
                    <div className="min-h-full w-full">
                      <div className="flex flex-col space-y-16 bg-white">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                          {sortedProducts.map((product) => (
                            <ProductCatalogueItem
                              key={product.id}
                              product={product}
                              navigateToProduct={(productId) =>
                                navigateToProduct(productId)
                              }
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <AnimationComponent
                      animation={noProducts}
                      message="Non hai ancora aggiunto dei prodotti"
                    />
                  )
                ) : (
                  <div className="min-h-full w-full">
                    <div className="bg-white">
                      {displaySubCategoryItems ? (
                        <div>
                          <div className="flex flex-col space-y-12 text-2xl font-bold">
                            {productsBySubCategory &&
                            productsBySubCategory.length > 0 ? (
                              <div className="min-h-full w-full">
                                <div className="bg-white">
                                  <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                                    {productsBySubCategory.map((product) => (
                                      <ProductCatalogueItem
                                        key={product.id}
                                        product={product}
                                        navigateToProduct={(productId) =>
                                          navigateToProduct(productId)
                                        }
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <AnimationComponent
                                animation={noProducts}
                                message="Non hai ancora aggiunto dei prodotti"
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                          {subCategories?.map((subCategory) => (
                            <SubCategoryItem
                              key={subCategory}
                              categoryName={subCategory}
                              onClick={() =>
                                filterSubcategoryItems(subCategory, sets ?? [])
                              }
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      </main>
      <AddProductDialogComponent
        productRepository={productRepository}
        quotesRepository={quotesRepository}
        allSets={sets}
        open={addProductDialogeOpen}
        onClose={() => setAddProductDialogOpen(false)}
        onConfirm={() => {
          setAddProductDialogOpen(false);
          loadProducts();
        }}
      />
      <AddSetDialogComponent
        productRepository={productRepository}
        quotesRepository={quotesRepository}
        sets={sets ?? []}
        categoryIndex={getCategoryIndexByName(category)}
        selectedSubcategory={subCategory}
        open={addSetDialogeOpen}
        onClose={() => setAddSetDialogOpen(false)}
        onConfirm={() => {
          setAddSetDialogOpen(false);
          loadProducts();
        }}
      />
    </div>
  );
}
