import { useLocation, useNavigate } from "react-router-dom";
import { ProductCart } from "../../domain/models";
import { AuthRepository } from "../../domain/repository/AuthRepository";
import {
  getYearFromTimestamp,
  getFormattedDateWithHours,
  getFormattedDate,
} from "../../utils/dateUtils";

import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { ProductsRepository } from "../../domain/repository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { ConfirmedQuote } from "../../domain/models/ConfirmedQuote";
import { useState } from "react";
import { QuoteType } from "../../data/datasource/QuotesDataSourceImpl";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import ConfirmDialogComponent, {
  DialogType,
} from "../components/admin/ConfirmDialogComponent";
import { LoaderView } from "../components/LoaderView";
import { useAdminModelController } from "../hook/useAdminModelController";

function ConfirmedQuoteDetailView({
  authRepository,
  productRepository,
  quotesRepository,
}: {
  authRepository: AuthRepository;
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const quote: ConfirmedQuote = location.state.quote;
  const [confirmDeleteDialog, setConfirmDeleteDialog] =
    useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const { handleDeleteQuote } = useAdminModelController(
    productRepository,
    quotesRepository,
  );

  const onDelete = async () => {
    setShowLoading(true);
    try {
      const result = await handleDeleteQuote(quote.id, QuoteType.Confirmed);

      if (result) {
        setShowLoading(false);
        showSuccessToast("Il preventivo è stato eliminato!");
        navigate("/dashboard/confirmedQuotes");
      } else {
        setShowLoading(false);
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
        );
      }
    } catch (error) {
      setShowLoading(false);
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
      );
    }
  };

  if (showLoading) {
    return <LoaderView />;
  }

  return (
    <div className="min-h-full w-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <main className="mx-auto w-full px-6 md:px-24">
        <div className="my-4 justify-between md:my-10">
          <div className="my-3 flex items-center justify-between text-xs sm:text-base">
            <h1 className="text-xl font-bold tracking-tight text-gray-900 md:text-4xl">
              Preventivo {quote.quoteNumber}/{getYearFromTimestamp(quote.date)}
            </h1>
            <div className="flex space-x-2">
              <button
                type="button"
                className="rounded-md bg-palette-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark"
                onClick={() => window.open(quote.pdfUrl, "_blank")}
              >
                Visualizza preventivo
              </button>
              <button
                type="button"
                className="rounded-md bg-red-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-red-800"
                onClick={() => setConfirmDeleteDialog(true)}
              >
                Elimina preventivo
              </button>
            </div>
          </div>
          <table className="mt-4 w-full border-collapse overflow-hidden rounded-lg border outline outline-1 outline-gray-300">
            <tbody>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Data:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {getFormattedDateWithHours(quote.date)}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Cliente:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {quote.name}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Email:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {quote.email}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Telefono:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {quote.phone}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Partita IVA:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {quote.pIVA !== ""
                    ? quote.pIVA.toUpperCase()
                    : "Non specificata"}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Codice Univoco:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {quote.codUnivoco !== ""
                    ? quote.codUnivoco.toUpperCase()
                    : "Non specificato"}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Codice Fiscale:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {quote.codFiscale !== ""
                    ? quote.codFiscale.toUpperCase()
                    : "Non specificato"}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Indirizzo di fatturazione:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {quote.address === "" ||
                  quote.address === null ||
                  quote.address === undefined
                    ? "Non specificato"
                    : quote.address}{" "}
                  {quote.city} {quote.cap}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Indirizzo di spedizione:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {quote.shippingAddress === ""
                    ? "Non specificato"
                    : quote.shippingAddress}{" "}
                  {quote.shippingCity} {quote.shippingCap}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Data Consegna:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {getFormattedDate(quote.deliveryDate) ?? "Non specificata"}{" "}
                  {quote.deliveryTime ?? ""}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Data Evento:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {getFormattedDate(quote.eventDate) ?? "Non specificata"}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Data Ritiro:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {getFormattedDate(quote.returnDate) ?? "Non specificata"}{" "}
                  {quote.returnTime}
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2 font-bold">
                  Ritiro in loco:
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {quote.localPickup ? "Si" : "No"}
                </td>
              </tr>
            </tbody>
          </table>
          <label
            htmlFor="cart"
            className="mt-10 block text-2xl font-bold leading-6 text-gray-900"
          >
            Carrello
          </label>
          <div
            className="mt-6 space-y-3 rounded-lg border border-gray-300 bg-white px-2 py-4 sm:px-6"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {quote.cart.length > 0 ? (
              quote.cart.map((product: ProductCart) => (
                <div
                  className="flex rounded-lg bg-white sm:flex-row"
                  key={product.id}
                >
                  <img
                    className="m-2 h-24 w-24 rounded-md border object-contain object-center"
                    src={product.image}
                    alt="product"
                  />
                  <div className="flex w-full flex-col justify-center space-y-2 px-4 py-4">
                    <span className="text-lg font-semibold">
                      {product.title}
                    </span>
                    <span className="float-right">
                      Quantità: {product.quantity}
                    </span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <p className="text-lg font-bold">
                      {product.price.toFixed(2).replace(".", ",")}€
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="font-semibold">
                Non hai ancora aggiunto nessun prodotto al carrello
              </p>
            )}
          </div>
          <table className="mt-4 w-full border-collapse overflow-hidden rounded-lg border outline outline-1 outline-gray-300">
            <tbody>
              <tr>
                <td className="border px-4 py-2 font-bold">
                  Costi di spedizione
                </td>
                <td className="border px-4 py-2">
                  {quote.shippingPrice.toFixed(2).replace(".", ",")}€
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2 font-bold">
                  Totale preventivo
                </td>
                <td className="border px-4 py-2">
                  {quote.totalPrice.toFixed(2).replace(".", ",") ?? 0}€
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mt-10"></div>
        </div>
      </main>
      <FooterComponent />
      <ConfirmDialogComponent
        type={DialogType.Delete}
        title="Elimina preventivo"
        body="Sei sicuro di voler eliminare questo preventivo? Questa azione non può essere annullata."
        open={confirmDeleteDialog}
        onClose={() => setConfirmDeleteDialog(false)}
        onConfirm={onDelete}
      />
    </div>
  );
}

export default ConfirmedQuoteDetailView;
