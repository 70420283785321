import { FooterComponent } from "../components/client/FooterComponent";
import { HeaderComponent } from "../components/client/HeaderComponent";
import confettiAnimation from "../../assets/lotties/confetti.json";
import Lottie from "react-lottie";
import { ProductsRepository } from "../../domain/repository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";

function ThankYouPage({
  productsRepository,
  quotesRepository,
}: {
  productsRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: confettiAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="min-h-full w-full">
      <HeaderComponent
        productsRepository={productsRepository}
        quotesRepository={quotesRepository}
      />
      <div className="grid p-8 md:grid-cols-5 md:p-16">
        <div className="col-span-3 md:col-span-2">
          <p className="text-4xl font-bold">
            Grazie per la tua richiesta di preventivo!
          </p>
          <p className="mt-6">
            Grazie per averci contattato per il tuo prossimo evento speciale.
            Abbiamo ricevuto la tua richiesta e ci impegniamo a fornirti un
            preventivo dettagliato entro breve.
            <br />
            <br />
            Nel frattempo, sentiti libero di esplorare il nostro sito per
            scoprire tutti i nostri prodotti e servizi disponibili. Siamo qui
            per aiutarti a rendere il tuo evento unico e indimenticabile!
          </p>

          <div className="mt-10 flex items-center justify-center sm:justify-start">
            <a
              href="/"
              className="rounded-md bg-palette-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark focus-visible:bg-palette-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Torna alla home
            </a>
          </div>
        </div>
        <div className="col-span-3">
          <Lottie options={defaultOptions} height={300} width={300} />
        </div>
      </div>

      <FooterComponent />
    </div>
  );
}

export default ThankYouPage;
