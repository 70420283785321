import {
  QuerySnapshot,
  DocumentData,
  collection,
  getDocs,
  QueryDocumentSnapshot,
  query,
  where,
} from "firebase/firestore";
import { ProductsDataSource } from "./ProductsDataSource";
import { app, db } from "../../firebase.config";
import {
  ApiResponse,
  ResponseFailure,
  ResponseSuccess,
} from "../models/ApiResponse";
import { NewProductData, ProductData } from "../../domain/models";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { NewSet } from "../../domain/models/NewSet";
import { Set } from "../../domain/models/Set";

export class ProductsDataSourceImpl implements ProductsDataSource {
  productsRef = collection(db, "products");
  pendingQuotesRef = collection(db, "pendingQuotes");
  setsRef = collection(db, "sets");

  sets: QueryDocumentSnapshot<DocumentData, DocumentData>[] | null = null;
  cachedProducts: QueryDocumentSnapshot<DocumentData, DocumentData>[] | null =
    null;
  functions = getFunctions(app, process.env.REACT_APP_CF_REGION);

  async getSets(): Promise<
    ApiResponse<QueryDocumentSnapshot<DocumentData, DocumentData>[] | null>
  > {
    try {
      if (this.sets != null) {
        return new ResponseSuccess(this.sets);
      } else {
        const setsSnapthot = await getDocs(this.setsRef);
        this.sets = setsSnapthot.docs;
        return new ResponseSuccess(setsSnapthot);
      }
    } catch (e) {
      return new ResponseFailure(e);
    }
  }

  async addSet(set: NewSet): Promise<ApiResponse<string>> {
    try {
      let category = set.category.toLowerCase();

      const addSetFunction = httpsCallable(this.functions, "addSet");
      const result = await addSetFunction({
        name: set.name,
        category: category,
        subcategory: set.subcategory,
        image: null,
      });

      const data: any = result.data;
      const docId = data.docId;
      return new ResponseSuccess(docId);
    } catch (error) {
      console.error("Error adding product:", error);
      return new ResponseFailure("Something went wrong");
    }
  }

  async updateSet(
    documentId: string,
    set: NewSet,
  ): Promise<ApiResponse<boolean>> {
    try {
      const updateSetFunction = httpsCallable(this.functions, "updateSet");
      await updateSetFunction({
        documentId: documentId,
        name: set.name,
        category: set.category,
        subcategory: set.subcategory,
        image: set.image,
      });

      const q = query(this.setsRef, where("__name__", "==", documentId));
      const querySnapshot = await getDocs(q);
      if (this.sets) {
        const index = this.sets.findIndex((item) => item.id === documentId);
        if (index !== -1) {
          this.sets[index] = querySnapshot.docs[0];
        } else {
          this.sets?.push(...querySnapshot.docs);
        }
      }
      return new ResponseSuccess(true);
    } catch (error) {
      return new ResponseFailure("Something went wrong");
    }
  }

  async updateSetName(
    documentId: string,
    setName: string,
  ): Promise<ApiResponse<boolean>> {
    try {
      const updateSetFunction = httpsCallable(this.functions, "updateSet");
      await updateSetFunction({
        documentId: documentId,
        name: setName,
      });

      const q = query(this.setsRef, where("__name__", "==", documentId));
      const querySnapshot = await getDocs(q);
      if (this.sets) {
        const index = this.sets.findIndex((item) => item.id === documentId);
        if (index !== -1) {
          this.sets[index] = querySnapshot.docs[0];
        } else {
          this.sets?.push(...querySnapshot.docs);
        }
      }
      return new ResponseSuccess(true);
    } catch (error) {
      return new ResponseFailure("Something went wrong");
    }
  }

  async deleteSet(set: Set): Promise<ApiResponse<boolean>> {
    try {
      const deleteSetFunction = httpsCallable(this.functions, "deleteSet");
      await deleteSetFunction({
        id: set.id,
      });
      return new ResponseSuccess(true);
    } catch (error) {
      return new ResponseFailure("Errore durante l'eliminazione del set");
    }
  }

  async getAllProducts(): Promise<
    ApiResponse<QuerySnapshot<DocumentData, DocumentData>>
  > {
    try {
      if (this.cachedProducts != null) {
        return new ResponseSuccess(this.cachedProducts);
      }
      const productsSnapshot = await getDocs(this.productsRef);
      this.cachedProducts = productsSnapshot.docs;
      return new ResponseSuccess(productsSnapshot);
    } catch (e) {
      return new ResponseFailure("Something went wrong");
    }
  }

  async addProduct(product: NewProductData): Promise<ApiResponse<string>> {
    try {
      let category = product.category.toLowerCase();
      const addProductFunction = httpsCallable(this.functions, "addProduct");
      const result = await addProductFunction({
        category: category,
        title: product.title,
        description: product.description,
        price: product.price,
        discount: product.discount,
        images: product.images,
      });

      const data: any = result.data;
      const docId = data.docId;
      return new ResponseSuccess(docId);
    } catch (error) {
      console.error("Error adding product:", error);
      return new ResponseFailure("Something went wrong");
    }
  }

  async updateProduct(
    documentId: string,
    product: NewProductData,
  ): Promise<ApiResponse<boolean>> {
    try {
      const updateProductFunction = httpsCallable(
        this.functions,
        "updateProduct",
      );
      await updateProductFunction({
        documentId: documentId,
        category: product.category.toLowerCase(),
        title: product.title,
        description: product.description,
        price: product.price,
        discount: product.discount,
        images: product.images,
      });

      const q = query(this.productsRef, where("__name__", "==", documentId));
      const querySnapshot = await getDocs(q);

      if (this.cachedProducts) {
        const index = this.cachedProducts.findIndex(
          (item) => item.id === documentId,
        );
        if (index !== -1) {
          this.cachedProducts[index] = querySnapshot.docs[0];
        } else {
          this.cachedProducts?.push(...querySnapshot.docs);
        }
      }

      return new ResponseSuccess(true);
    } catch (error) {
      return new ResponseFailure("Something went wrong");
    }
  }

  async uploadImage(
    category: string,
    documentId: string,
    images: File[],
  ): Promise<ApiResponse<string[]>> {
    const uploadedImageUrls: string[] = [];
    const storage = getStorage();

    try {
      for (const image of images) {
        const fileName = image.name;
        const storageRef = ref(
          storage,
          `images/${category}/${documentId}/${fileName}`,
        );

        await uploadBytes(storageRef, image);
        const imageUrl = await getDownloadURL(storageRef);
        uploadedImageUrls.push(imageUrl);
      }

      return new ResponseSuccess(uploadedImageUrls);
    } catch (error) {
      return new ResponseFailure(
        "Errore durante il caricamento delle immagini",
      );
    }
  }

  async deleteProduct(product: ProductData): Promise<ApiResponse<boolean>> {
    try {
      const deleteProductFunction = httpsCallable(
        this.functions,
        "deleteProduct",
      );
      await deleteProductFunction({
        id: product.id,
      });
      return new ResponseSuccess(true);
    } catch (error) {
      return new ResponseFailure("Errore durante l'eliminazione del prodotto");
    }
  }

  async deleteProductImages(
    product: ProductData | Set,
  ): Promise<ApiResponse<boolean>> {
    try {
      const storage = getStorage();
      const productImagesRef = ref(
        storage,
        `images/${product.category}/${product.id}/`,
      );

      const listResult = await listAll(productImagesRef);
      const deletePromises = listResult.items.map((item) => deleteObject(item));
      await Promise.all(deletePromises);
      const updatedDocumentSnapshots = this.cachedProducts?.filter(
        (snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>) => {
          return snapshot.id !== product.id;
        },
      );
      this.cachedProducts = updatedDocumentSnapshots ?? [];
      return new ResponseSuccess(true);
    } catch (error) {
      return new ResponseFailure(
        "Impossibile eliminare le immagini del prodotto",
      );
    }
  }

  async deleteProductImage(
    documentId: string,
    images: string[],
  ): Promise<ApiResponse<boolean>> {
    try {
      const storage = getStorage();

      await Promise.all(
        images.map(async (imagePath) => {
          const productImageRef = ref(storage, imagePath);
          await deleteObject(productImageRef);
        }),
      );

      const updatedDocumentSnapshots = this.cachedProducts?.filter(
        (snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>) => {
          return snapshot.id !== documentId;
        },
      );

      this.cachedProducts = updatedDocumentSnapshots ?? [];

      return new ResponseSuccess(true);
    } catch (error) {
      return new ResponseFailure(
        "Impossibile eliminare l'immagini del prodotto",
      );
    }
  }

  async deleteSetImage(
    documentId: string,
    image: string,
  ): Promise<ApiResponse<boolean>> {
    try {
      const storage = getStorage();
      const productImageRef = ref(storage, image);

      await deleteObject(productImageRef);
      const updatedDocumentSnapshots = this.sets?.filter(
        (snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>) => {
          return snapshot.id !== documentId;
        },
      );
      this.sets = updatedDocumentSnapshots ?? [];
      return new ResponseSuccess(true);
    } catch (error) {
      return new ResponseFailure("Impossibile eliminare l'immagine del set");
    }
  }
}
