import { DocumentData, QuerySnapshot } from "firebase/firestore";
import {
  PendingQuote,
  ProcessedQuote,
  QuoteRequest,
  Result,
} from "../../domain/models";
import { Success, Failure } from "../../domain/models/Result";
import { ResponseSuccess } from "../models/ApiResponse";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { QuotesDatasource } from "../datasource/QuotesDatasource";
import { NewProcessedQuote } from "../../domain/models/NewProcessedQuote";
import { FirebaseEmail } from "../../domain/models/FirebaseEmail";
import { QuoteType } from "../datasource/QuotesDataSourceImpl";
import { NewConfirmedQuote } from "../../domain/models/NewConfirmedQuote";
import { ConfirmedQuote } from "../../domain/models/ConfirmedQuote";

export class QuotesRepositoryImpl implements QuotesRepository {
  dataSource: QuotesDatasource;
  constructor(dataSource: QuotesDatasource) {
    this.dataSource = dataSource;
  }
  async createQuoteRequest(
    quoteRequest: QuoteRequest,
  ): Promise<Result<boolean>> {
    const quoteResponse =
      await this.dataSource.createQuoteRequest(quoteRequest);
    if (quoteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (quoteResponse as Failure<string>).error;
      return new Failure(error);
    }
  }
  async getPendingQuotes(): Promise<Result<PendingQuote[]>> {
    const pendingQuotes: PendingQuote[] = [];
    const pendingQuotesResponse = await this.dataSource.getPendingQuotes();
    if (pendingQuotesResponse instanceof ResponseSuccess) {
      const snapshot: QuerySnapshot<DocumentData, DocumentData> =
        pendingQuotesResponse.value;
      snapshot.forEach((doc) => {
        const quoteData = doc.data();
        const pendingQuote: PendingQuote = {
          id: doc.id,
          quoteNumber: quoteData.quoteNumber,
          name: quoteData.name,
          email: quoteData.email,
          phone: quoteData.phone,
          pIVA: quoteData.pIVA ?? "",
          codUnivoco: quoteData.codUnivoco ?? "",
          codFiscale: quoteData.codFiscale ?? "",
          address: quoteData.address,
          city: quoteData.city,
          cap: quoteData.cap,
          date: quoteData.date,
          cart: quoteData.cart,
          shippingPrice: quoteData.shippingPrice,
          discount: quoteData.discount,
          discountType: quoteData.discountType,
          totalPrice: quoteData.totalPrice,
          shippingAddress: quoteData.shippingAddress,
          shippingCity: quoteData.shippingCity,
          shippingCap: quoteData.shippingCap,
          deliveryDate: quoteData.deliveryDate,
          deliveryTime: quoteData.deliveryTime ?? "",
          eventDate: quoteData.eventDate,
          returnDate: quoteData.returnDate,
          returnTime: quoteData.returnTime ?? "",
          localPickup: quoteData.localPickup ?? false,
          text: quoteData.text,
        };
        pendingQuotes.push(pendingQuote);
      });
      return new Success(pendingQuotes);
    } else {
      const error: string = (pendingQuotesResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async updateQuote(
    quote: PendingQuote,
    quoteType: QuoteType,
    firebaseEmail?: FirebaseEmail,
  ): Promise<Result<boolean>> {
    const updateResponse = await this.dataSource.updateQuote(
      quote,
      quoteType,
      firebaseEmail,
    );
    if (updateResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (updateResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async getProcessedQuotes(): Promise<Result<ProcessedQuote[]>> {
    const processedQuotes: ProcessedQuote[] = [];
    const processedQuotesResponse = await this.dataSource.getProcessedQuotes();
    if (processedQuotesResponse instanceof ResponseSuccess) {
      const snapshot: QuerySnapshot<DocumentData, DocumentData> =
        processedQuotesResponse.value;
      snapshot.forEach((doc) => {
        const quoteData = doc.data();
        const processedQuote: ProcessedQuote = {
          id: doc.id,
          quoteNumber: quoteData.quoteNumber,
          name: quoteData.name || quoteData.toName,
          email: quoteData.email || quoteData.toEmail,
          phone: quoteData.phone,
          pIVA: quoteData.pIVA ?? "",
          codUnivoco: quoteData.codUnivoco ?? "",
          codFiscale: quoteData.codFiscale ?? "",
          address: quoteData.address,
          city: quoteData.city,
          cap: quoteData.cap,
          text: quoteData.text,
          date: quoteData.date,
          cart: quoteData.cart,
          shippingPrice: quoteData.shippingPrice,
          discount: quoteData.discount,
          discountType: quoteData.discountType,
          shippingAddress: quoteData.shippingAddress,
          shippingCity: quoteData.shippingCity,
          shippingCap: quoteData.shippingCap,
          deliveryDate: quoteData.deliveryDate,
          deliveryTime: quoteData.deliveryTime ?? "",
          eventDate: quoteData.eventDate,
          returnDate: quoteData.returnDate,
          returnTime: quoteData.returnTime ?? "",
          localPickup: quoteData.localPickup ?? false,
          totalPrice: quoteData.totalPrice,
          pdfUrl: quoteData.pdfUrl,
        };
        processedQuotes.push(processedQuote);
      });
      return new Success(processedQuotes);
    } else {
      const error: string = (processedQuotesResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async getConfirmedQuotes(): Promise<Result<ConfirmedQuote[]>> {
    const confirmedQuotes: ConfirmedQuote[] = [];
    const confirmedQuotesResponse = await this.dataSource.getConfirmedQuotes();
    if (confirmedQuotesResponse instanceof ResponseSuccess) {
      const snapshot: QuerySnapshot<DocumentData, DocumentData> =
        confirmedQuotesResponse.value;
      snapshot.forEach((doc) => {
        const quoteData = doc.data();
        const confirmedQuote: ConfirmedQuote = {
          id: doc.id,
          quoteNumber: quoteData.quoteNumber,
          email: quoteData.email,
          name: quoteData.name,
          phone: quoteData.phone,
          pIVA: quoteData.pIVA ?? "",
          codUnivoco: quoteData.codUnivoco ?? "",
          codFiscale: quoteData.codFiscale ?? "",
          address: quoteData.address,
          city: quoteData.city,
          cap: quoteData.cap,
          text: quoteData.text,
          date: quoteData.date,
          cart: quoteData.cart,
          shippingPrice: quoteData.shippingPrice,
          discount: quoteData.discount,
          discountType: quoteData.discountType,
          shippingAddress: quoteData.shippingAddress,
          shippingCity: quoteData.shippingCity,
          shippingCap: quoteData.shippingCap,
          deliveryDate: quoteData.deliveryDate,
          deliveryTime: quoteData.deliveryTime ?? "",
          eventDate: quoteData.eventDate,
          returnDate: quoteData.returnDate,
          returnTime: quoteData.returnTime ?? "",
          localPickup: quoteData.localPickup ?? false,
          totalPrice: quoteData.totalPrice,
          pdfUrl: quoteData.pdfUrl,
        };
        confirmedQuotes.push(confirmedQuote);
      });
      return new Success(confirmedQuotes);
    } else {
      const error: string = (confirmedQuotesResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async processPendingQuote(
    pendingQuoteId: string,
    newProcessedQuote: NewProcessedQuote,
    firebaseEmail: FirebaseEmail,
  ): Promise<Result<boolean>> {
    const quoteResponse = await this.dataSource.addProcessedQuote(
      pendingQuoteId,
      newProcessedQuote,
      firebaseEmail,
    );
    if (quoteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (quoteResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async processProcessedQuote(
    processedQuoteId: string,
    newConfirmedQuote: NewConfirmedQuote,
  ): Promise<Result<boolean>> {
    const quoteResponse = await this.dataSource.addConfirmedQuote(
      processedQuoteId,
      newConfirmedQuote,
    );
    if (quoteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (quoteResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async uploadPDFQuote(
    clientEmail: string,
    pdf: File,
    fileName: string,
  ): Promise<Result<string>> {
    const pdfResponse = await this.dataSource.uploadPDFQuote(
      clientEmail,
      pdf,
      fileName,
    );
    if (pdfResponse instanceof ResponseSuccess) {
      return new Success(pdfResponse.value);
    } else {
      const error: string = (pdfResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async deleteQuote(
    quoteId: string,
    quoteType: QuoteType,
  ): Promise<Result<boolean>> {
    const quoteResponse = await this.dataSource.deleteQuote(quoteId, quoteType);
    if (quoteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (quoteResponse as Failure<string>).error;
      return new Failure(error);
    }
  }
}
