import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { ProductData } from "../../domain/models/ProductData";
import { ProductsDataSource } from "../datasource/ProductsDataSource";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { Set, NewProductData, Result } from "../../domain/models";
import { Success, Failure } from "../../domain/models/Result";
import { ResponseSuccess } from "../models/ApiResponse";
import { NewSet } from "../../domain/models/NewSet";

export class ProductsRepositoryImpl implements ProductsRepository {
  dataSource: ProductsDataSource;
  constructor(dataSource: ProductsDataSource) {
    this.dataSource = dataSource;
  }

  async getSets(): Promise<Result<Set[] | null>> {
    const sets: Set[] = [];
    const setsResponse = await this.dataSource.getSets();
    if (setsResponse instanceof ResponseSuccess) {
      const snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>[] =
        setsResponse.value;
      snapshot.forEach((doc) => {
        const setsData = doc.data();
        const set: Set = {
          id: doc.id,
          name: setsData.name,
          category: setsData.category,
          subcategory: setsData.subcategory,
          image: setsData.image,
        };
        sets.push(set);
      });
      return new Success(sets);
    } else {
      const error: string = (setsResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async addSet(set: NewSet): Promise<Result<boolean>> {
    let images: File[] = [];
    images.push(set.image!);
    set.image = null;

    const result = await this.dataSource.addSet(set);
    if (result instanceof ResponseSuccess) {
      let category = set.category.toLowerCase();

      let imageUrls = await this.dataSource.uploadImage(
        category!,
        result.value,
        images,
      );

      if (imageUrls instanceof ResponseSuccess) {
        let updatedSet = {
          name: set.name,
          category: category,
          subcategory: set.subcategory,
          image: imageUrls.value[0],
        };
        await this.updateSet(result.value, updatedSet);
        return new Success(true);
      } else {
        return new Failure(false);
      }
    } else {
      const error: string = (result as Failure<string>).error;
      return new Failure(error);
    }
  }

  async getAllProducts(): Promise<Result<ProductData[]>> {
    const products: ProductData[] = [];
    const productsResponse = await this.dataSource.getAllProducts();
    if (productsResponse instanceof ResponseSuccess) {
      const snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>[] =
        productsResponse.value;
      snapshot.forEach((doc) => {
        const productData = doc.data();
        const product: ProductData = {
          id: doc.id,
          category: productData.category,
          title: productData.title,
          description: productData.description,
          price: productData.price,
          discount: productData.discount,
          images: productData.images,
        };
        products.push(product);
      });
      return new Success(products);
    } else {
      const error: string = (productsResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async addProduct(
    product: NewProductData,
    categoryLevel: boolean,
  ): Promise<Result<boolean>> {
    let images = product.images;
    product.images = [];

    const result = await this.dataSource.addProduct(product);
    if (result instanceof ResponseSuccess) {
      let category = product.category;
      if (categoryLevel) {
        let result = product.category.toLowerCase();
        if (result !== undefined) {
          category = result;
        }
      }

      let imageUrls = await this.dataSource.uploadImage(
        category!,
        result.value,
        images,
      );

      if (imageUrls instanceof ResponseSuccess) {
        let updatedProduct = {
          category: category,
          title: product.title,
          description: product.description,
          price: product.price,
          discount: product.discount,
          images: imageUrls.value,
        };
        await this.updateProduct(result.value, updatedProduct);
        return new Success(true);
      } else {
        return new Failure(false);
      }
    } else {
      const error: string = (result as Failure<string>).error;
      return new Failure(error);
    }
  }

  async updateProduct(
    documentId: string,
    product: any,
  ): Promise<Result<boolean>> {
    const updateResponse = await this.dataSource.updateProduct(
      documentId,
      product,
    );
    if (updateResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (updateResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async updateSet(documentId: string, set: any): Promise<Result<boolean>> {
    const updateResponse = await this.dataSource.updateSet(documentId, set);
    if (updateResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (updateResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async updateSetName(
    documentId: string,
    setName: string,
  ): Promise<Result<boolean>> {
    const updateResponse = await this.dataSource.updateSetName(
      documentId,
      setName,
    );
    if (updateResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (updateResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async deleteSet(set: Set): Promise<Result<boolean>> {
    const deleteResponse = await this.dataSource.deleteSet(set);
    if (deleteResponse instanceof ResponseSuccess) {
      const deleteImageResponse = await this.dataSource.deleteSetImage(
        set.id,
        set.image,
      );
      if (deleteImageResponse instanceof ResponseSuccess) {
        return new Success(true);
      } else {
        const error: string = (deleteImageResponse as Failure<string>).error;
        return new Failure(error);
      }
    } else {
      const error: string = (deleteResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async deleteProduct(product: ProductData): Promise<Result<boolean>> {
    const deleteResponse = await this.dataSource.deleteProduct(product);
    if (deleteResponse instanceof ResponseSuccess) {
      const deleteImageResponse =
        await this.dataSource.deleteProductImages(product);
      if (deleteImageResponse instanceof ResponseSuccess) {
        return new Success(true);
      } else {
        const error: string = (deleteImageResponse as Failure<string>).error;
        return new Failure(error);
      }
    } else {
      const error: string = (deleteResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async deleteProductImages(product: ProductData): Promise<Result<boolean>> {
    const deleteResponse = await this.dataSource.deleteProductImages(product);
    if (deleteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (deleteResponse as Failure<string>).error;

      return new Failure(error);
    }
  }

  async deleteProductImage(
    productId: string,
    image: string,
  ): Promise<Result<boolean>> {
    let images = [image];
    const deleteResponse = await this.dataSource.deleteProductImage(
      productId,
      images,
    );
    if (deleteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (deleteResponse as Failure<string>).error;

      return new Failure(error);
    }
  }

  async editProduct(
    documentId: string,
    product: NewProductData,
    currentUrls: string[],
    coverImage: File,
    productImages: File[],
    imagesToDelete: string[],
  ): Promise<Result<boolean>> {
    let editedProduct: {
      category: string;
      description: string;
      price: number;
      discount: number;
      title: string;
      images: string[];
    } = {
      category: product.category,
      description: product.description,
      price: product.price!,
      discount: product.discount!,
      title: product.title,
      images: [],
    };

    let newUrls: string[] = currentUrls;
    let newImages: File[] = [];
    if (coverImage !== undefined) {
      newImages.push(coverImage);
    }
    if (productImages !== null) {
      newImages = newImages.concat(productImages);
    }

    if (imagesToDelete.length > 0) {
      const imagesToDeleteResponse = await this.dataSource.deleteProductImage(
        documentId,
        imagesToDelete,
      );

      if (imagesToDeleteResponse instanceof ResponseSuccess) {
        newUrls = newUrls.filter((url) => !imagesToDelete.includes(url));
      } else {
        const error: string = (imagesToDeleteResponse as Failure<string>).error;

        return new Failure(error);
      }
    }

    if (newImages.length > 0) {
      let imageUrls = await this.dataSource.uploadImage(
        product.category,
        documentId,
        newImages,
      );

      if (imageUrls instanceof ResponseSuccess) {
        if (coverImage !== null) {
          newUrls.unshift(...imageUrls.value);
        } else {
          newUrls = newUrls.concat(imageUrls.value);
        }
      } else {
        const error: string = (imageUrls as Failure<string>).error;

        return new Failure(error);
      }
    }

    editedProduct.images = newUrls;

    await this.updateProduct(documentId, editedProduct);
    return new Success(true);
  }

  async deleteSetImage(
    documentId: string,
    image: string,
  ): Promise<Result<boolean>> {
    const deleteResponse = await this.dataSource.deleteSetImage(
      documentId,
      image,
    );
    if (deleteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (deleteResponse as Failure<string>).error;

      return new Failure(error);
    }
  }
}
