function SetCatalogueItem({
  productName,
  productImage,
  navigateToProduct,
}: {
  productName: string;
  productImage: string;
  navigateToProduct: (productId: string) => void;
}) {
  return (
    <div
      key={productName}
      className="group relative"
      onClick={() => navigateToProduct(productName)}
    >
      <div className="aspect-h-1 aspect-w-1 relative w-full">
        <img
          src={productImage}
          alt="Example"
          className="h-full w-full rounded-lg object-contain"
        />
      </div>

      <div className="mt-4 flex flex-col justify-between">
        <h3 className="mx-auto text-lg font-medium text-gray-900">
          <span aria-hidden="true" className="absolute inset-0" />
          {productName}
        </h3>
      </div>
    </div>
  );
}

export default SetCatalogueItem;
