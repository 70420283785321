import { QuoteType } from "../../data/datasource/QuotesDataSourceImpl";
import { Success } from "../models/Result";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function deleteQuote(
  quoteId: string,
  quoteType: QuoteType,
  repository: QuotesRepository,
): Promise<boolean> {
  const result = await repository.deleteQuote(quoteId, quoteType);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
