import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useAddToCartContext } from "../../../context/cart";
import { ProductData } from "../../../domain/models/ProductData";
import { showSuccessToast, showWarningToast } from "../../../utils/toastUtils";

export function ProductFormComponent(productData: ProductData) {
  const [quantity, setQuantity] = useState<number | undefined>(1);
  const addToCart = useAddToCartContext();

  //   const atcBtnStyle = isLoading ?
  //     `pt-3 pb-2 bg-palette-primary text-white w-full mt-2 rounded-sm font-primary font-semibold text-xl flex
  //                       justify-center items-baseline  hover:bg-palette-dark opacity-25 cursor-none`
  //     :
  //     `pt-3 pb-2 bg-palette-primary text-white w-full mt-2 rounded-sm font-primary font-semibold text-xl flex
  //                       justify-center items-baseline  hover:bg-palette-dark`
  const atcBtnStyle = `pt-3 pb-3 bg-palette-primary text-white w-full mt-8 rounded-lg font-primary font-semibold text-xl flex 
                      justify-center items-baseline  hover:bg-palette-dark`;

  async function handleAddToCart() {
    // update store context
    if (quantity && quantity > 0) {
      addToCart({
        id: productData.id,
        title: productData.title,
        quantity: quantity ?? 1,
        category: productData.category,
        price: productData.price,
        image: productData.images[0],
      });
      setQuantity(1);
      showSuccessToast("Prodotto aggiunto al carrello!");
    } else {
      showWarningToast("Quantità minima 1");
    }
  }

  function updateQuantity(e: string) {
    if (e.includes("-")) {
      return;
    }

    if (e === "") {
      setQuantity(undefined);
    } else {
      setQuantity(Math.floor(parseInt(e)));
    }
  }

  return (
    <div className="w-full">
      <div className="flex w-full justify-start space-x-2">
        <div className="flex flex-grow-0 flex-col items-start space-y-1">
          <label className="text-base text-gray-500">Qtà.</label>
          <input
            type="number"
            inputMode="numeric"
            id="quantity"
            name="quantity"
            min="1"
            step="1"
            placeholder={quantity?.toString()}
            value={quantity}
            onChange={(e) => updateQuantity(e.target.value)}
            onBlur={() => {
              if (quantity === undefined) {
                setQuantity(1);
              }
            }}
            className="form-input w-16 appearance-auto rounded-sm border border-gray-300 text-gray-900 focus:border-palette-light focus:ring-palette-light"
          />
        </div>
      </div>
      <button
        className={atcBtnStyle}
        aria-label="cart-button"
        onClick={handleAddToCart}
      >
        Aggiungi al carrello
        <FontAwesomeIcon icon={faShoppingCart} className="my-auto ml-2 w-5" />
      </button>
    </div>
  );
}
