export function phoneIsValid(phone: string) {
  var phoneFormat =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  if (phone.match(phoneFormat)) {
    return true;
  } else {
    return false;
  }
}
export function mailIsValid(email: string) {
  var mailformat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export function codiceFiscaleIsValid(cf: string): boolean {
  const regex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i;
  return regex.test(cf.toUpperCase());
}

export function partitaIVAIsValid(piva: string): boolean {
  const regex = /^\d{11}$/;
  return regex.test(piva);
}

export function codiceUnivocoIsValid(codice: string): boolean {
  const regex = /^[A-Za-z0-9]{6,7}$/;
  return regex.test(codice);
}
