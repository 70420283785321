import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { useAdminModelController } from "../../hook/useAdminModelController";
import { ProductsRepository } from "../../../domain/repository";
import { QuotesRepository } from "../../../domain/repository/QuotesRepository";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import { resizeFile } from "../../../utils/imageUtils";
import { NewSet } from "../../../domain/models/NewSet";
import { categories, findSubcategories } from "../../../utils/productUtils";
import { Set } from "../../../domain/models/Set";

export default function AddSetDialogComponent({
  productRepository,
  quotesRepository,
  sets,
  categoryIndex,
  selectedSubcategory,
  open,
  onClose,
  onConfirm,
}: {
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
  sets: Set[];
  categoryIndex: number;
  selectedSubcategory: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [coverImagePreview, setCoverImagePreview] = useState<string>("");
  const [subCategories, setSubCategories] = useState<string[] | null>(null);
  const [formData, setFormData] = useState<NewSet>({
    name: "",
    category: categories[categoryIndex] || "",
    subcategory: "",
    image: null,
  });
  const { name, category, subcategory, image } = formData;

  useEffect(() => {
    getSubCategories(formData.category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) {
      setFormData((prevState) => ({
        ...prevState,
        category: categories[categoryIndex],
      }));
      getSubCategories(categories[categoryIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIndex, open]);

  const { handleAddSet } = useAdminModelController(
    productRepository,
    quotesRepository,
  );

  const getSubCategories = (_cat: string) => {
    if (_cat !== null && _cat !== "" && _cat !== undefined) {
      let c = _cat.toLowerCase();
      let cats = findSubcategories(c!);
      setSubCategories(cats);

      if (cats !== null && cats !== undefined) {
        let subCat = cats!.filter((cat) => cat === selectedSubcategory)[0];

        setFormData((prevState) => ({
          ...prevState,
          subcategory: subCat,
        }));
      }
    } else {
      setSubCategories(null);
    }
  };

  const onAddProductCover = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const resizeImage = (image: File) => {
        return new Promise<File>((resolve) => {
          resizeFile(image, (resizedImage: File) => {
            resolve(resizedImage);
          });
        });
      };

      resizeImage(file).then((resizedImage) => {
        setFormData((prevState) => ({
          ...prevState,
          image: resizedImage,
        }));

        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const preview = event.target.result.toString();
            setCoverImagePreview(preview);
          }
        };
        reader.readAsDataURL(resizedImage);
      });
    }
  };

  const onMutate = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    let value: any = e.target.value;

    if (value === "true" || value === "false") {
      value = value === "true";
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  const handleCoverDelete = () => {
    setCoverImagePreview("");
    setFormData((prevState) => ({
      ...prevState,
      image: null,
    }));
  };

  const addProductToDb = async () => {
    if (sets.filter((s) => s.name.toLowerCase === formData.name.toLowerCase))
      if (validFields()) {
        const filteredSets = sets.filter(
          (s) => s.name.toLowerCase() === formData.name.toLowerCase(),
        );

        if (filteredSets.length > 0) {
          showErrorToast("Esiste già un set con questo nome!");
        } else {
          setShowLoading(true);

          try {
            const result = await handleAddSet(formData);
            if (result) {
              showSuccessToast("Il prodotto è stato aggiunto con successo!");
            } else {
              showErrorToast(
                "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
              );
            }
          } catch (error) {
            showErrorToast(
              "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
            );
          } finally {
            setShowLoading(false);
          }

          clearFields();
          onConfirm();
        }
      } else {
        showErrorToast("Completa tutti i campi");
      }
  };

  const clearFields = () => {
    setFormData({
      name: "",
      category: "",
      subcategory: "",
      image: null,
    });
    setCoverImagePreview("");
  };

  const validFields = () => {
    return (
      name !== "" &&
      name !== undefined &&
      category !== "" &&
      category !== undefined &&
      image !== null &&
      image !== undefined
    );
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="bg-white px-4 py-4 sm:p-6 sm:pb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-center text-lg font-semibold leading-6 text-gray-900"
                    >
                      Aggiungi Set
                    </DialogTitle>
                    <div className="mt-2">
                      <form>
                        <div className="pb-8">
                          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Nome
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  value={name}
                                  onChange={onMutate}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="category"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Categoria
                              </label>
                              <div className="mt-2">
                                <select
                                  id="category"
                                  value={category}
                                  onChange={(e) => {
                                    onMutate(e);
                                    getSubCategories(e.target.value);
                                  }}
                                  name="category"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                                >
                                  <option value="">
                                    Seleziona una categoria
                                  </option>
                                  {categories.map((category, index) => (
                                    <option key={index}>{category}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="subcategory"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Sottocategoria
                              </label>
                              <div className="mt-2">
                                <select
                                  id="subcategory"
                                  value={subcategory}
                                  onChange={onMutate}
                                  name="subcategory"
                                  disabled={subCategories === null}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                                >
                                  <option value="">
                                    Seleziona una sottocategoria
                                  </option>
                                  {subCategories?.map((subCategory, index) => (
                                    <option key={index}>{subCategory}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            {coverImagePreview === "" && (
                              <div className="col-span-full">
                                <label
                                  htmlFor="cover-photo"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Immagine di copertina
                                </label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2">
                                  <div className="text-center">
                                    <PhotoIcon
                                      className="mx-auto h-12 w-12 text-gray-300"
                                      aria-hidden="true"
                                    />
                                    <div className="mt-2 flex text-sm leading-6 text-gray-600">
                                      <label
                                        htmlFor="cover-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-palette-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-palette-primary focus-within:ring-offset-2 hover:bg-palette-light"
                                      >
                                        <span>Carica la cover</span>
                                        <input
                                          type="file"
                                          id="cover-upload"
                                          name="cover-upload"
                                          className="sr-only"
                                          accept=".jpg,.png,.jpeg"
                                          onChange={onAddProductCover}
                                        />
                                      </label>
                                      <p className="pl-1">o trascinala qua</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {coverImagePreview !== "" && (
                              <div className="col-span-full">
                                <label
                                  htmlFor="cover-photo"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Immagine di copertina
                                </label>
                                <div className="mt-2 rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                                  <div className="relative flex items-center justify-center">
                                    <img
                                      src={coverImagePreview}
                                      alt="Immagine cover"
                                      className="max-h-60 object-contain"
                                    />
                                    <div className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity hover:opacity-100">
                                      <div className="absolute inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6 cursor-pointer text-white"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          onClick={handleCoverDelete}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {showLoading ? (
                  <div className="w-full rounded bg-gray-50 px-4 py-4">
                    <div
                      style={{ width: "100%" }}
                      className="shim-light absolute top-0 rounded-full"
                    >
                      <p className="p-1 text-center text-base font-bold leading-6 text-gray-900">
                        Caricamento...
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="bg-gray-50 px-4 py-4 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-palette-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark sm:ml-3 sm:w-auto"
                      onClick={addProductToDb}
                    >
                      Aggiungi
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-700 hover:text-white sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setFormData({
                          category: "",
                          name: "",
                          subcategory: "",
                          image: null,
                        });
                        setCoverImagePreview("");
                        onClose();
                      }}
                    >
                      Cancella
                    </button>
                  </div>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
