import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { useAdminModelController } from "../../hook/useAdminModelController";
import { ProductsRepository } from "../../../domain/repository";
import { QuotesRepository } from "../../../domain/repository/QuotesRepository";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import { resizeFile } from "../../../utils/imageUtils";
import { ProductData, Set } from "../../../domain/models";

export default function EditSetDialogComponent({
  productRepository,
  quotesRepository,
  set,
  allProducts,
  open,
  onClose,
  onConfirm,
}: {
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
  set: Set;
  allProducts: ProductData[];
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [coverImagePreview, setCoverImagePreview] = useState<string>(set.image);
  const [newImageFile, setNewImageFile] = useState<File | null>(null);
  const [formData, setFormData] = useState<Set>(set);
  const { name, category, image } = formData;

  const {
    handleAddSet,
    handleUpdateSetName,
    handleDeleteSet,
    handleUpdateProduct,
  } = useAdminModelController(productRepository, quotesRepository);

  const onAddProductCover = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setNewImageFile(file);

      const resizeImage = (image: File) => {
        return new Promise<File>((resolve) => {
          resizeFile(image, (resizedImage: File) => {
            resolve(resizedImage);
          });
        });
      };

      resizeImage(file).then((resizedImage) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const preview = event.target.result.toString();
            setCoverImagePreview(preview);
          }
        };
        reader.readAsDataURL(resizedImage);
      });
    }
  };

  const onMutate = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    let value: any = e.target.value;

    if (value === "true" || value === "false") {
      value = value === "true";
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  const updateProductCategory = async (product: ProductData) => {
    const updatedProduct = {
      ...product,
      category: formData.name,
    };
    return await handleUpdateProduct(updatedProduct.id, updatedProduct);
  };

  const updateSetToDb = async () => {
    if (!newImageFile) {
      if (formData.name !== "") {
        setShowLoading(true);

        try {
          const result = await handleUpdateSetName(formData.id, formData.name);
          if (result) {
            if (allProducts && allProducts.length > 0) {
              const updatePromises = allProducts.map((product) =>
                updateProductCategory(product),
              );
              await Promise.all(updatePromises);
            }
            showSuccessToast("Il set è stato aggiornato con successo!");
          } else {
            showErrorToast(
              "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
            );
          }
        } catch (error) {
          showErrorToast(
            "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
          );
        } finally {
          setShowLoading(false);
          onConfirm();
        }
      } else {
        showErrorToast("Completa tutti i campi");
      }
    } else {
      const updatedSet = {
        name: formData.name,
        category: formData.category,
        subcategory: formData.subcategory ?? "",
        image: newImageFile,
      };

      if (validFields()) {
        setShowLoading(true);

        try {
          const result = await handleAddSet(updatedSet);
          if (result) {
            await handleDeleteSet(set);

            if (allProducts && allProducts.length > 0) {
              const updatePromises = allProducts.map((product) =>
                updateProductCategory(product),
              );
              await Promise.all(updatePromises);
            }

            showSuccessToast("Il set è stato aggiornato con successo!");
          } else {
            showErrorToast(
              "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
            );
          }
        } catch (error) {
          showErrorToast(
            "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
          );
        } finally {
          setShowLoading(false);
          onConfirm();
        }
      } else {
        showErrorToast("Completa tutti i campi");
      }
    }
  };

  const validFields = () => {
    return (
      name !== "" &&
      name !== undefined &&
      category !== "" &&
      category !== undefined &&
      image !== null &&
      image !== undefined
    );
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="bg-white px-4 py-4 sm:p-6 sm:pb-4">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-center text-lg font-semibold leading-6 text-gray-900"
                    >
                      Modifica Set
                    </DialogTitle>
                    <div className="mt-2">
                      <form>
                        <div className="pb-8">
                          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Nome
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  value={name}
                                  onChange={onMutate}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="col-span-full mt-2 rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                              <div className="relative flex flex-col items-center space-y-2">
                                <img
                                  src={coverImagePreview}
                                  alt="Immagine cover"
                                  className="h-auto max-h-64 w-auto max-w-64"
                                />
                                <label
                                  htmlFor="cover-upload"
                                  className="relative cursor-pointer font-semibold text-palette-primary hover:text-palette-dark"
                                >
                                  <span>Sostituisci immagine</span>
                                  <input
                                    id="cover-upload"
                                    onChange={onAddProductCover}
                                    type="file"
                                    accept=".jpg,.png,.jpeg,.svg"
                                    className="sr-only"
                                    required
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {showLoading ? (
                  <div className="w-full rounded bg-gray-50 px-4 py-4">
                    <div
                      style={{ width: "100%" }}
                      className="shim-light absolute top-0 rounded-full"
                    >
                      <p className="p-1 text-center text-base font-bold leading-6 text-gray-900">
                        Caricamento...
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="bg-gray-50 px-4 py-4 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-palette-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark sm:ml-3 sm:w-auto"
                      onClick={updateSetToDb}
                    >
                      Conferma
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-700 hover:text-white sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setFormData({
                          id: "",
                          category: "",
                          name: "",
                          subcategory: "",
                          image: "",
                        });
                        setCoverImagePreview("");
                        onClose();
                      }}
                    >
                      Cancella
                    </button>
                  </div>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
