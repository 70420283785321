import logo from "../../assets/images/logo-dap-rent.png";

export function LoaderView() {
  return (
    <div className="fixed inset-0 flex h-full w-full flex-col items-center justify-center space-y-4 overflow-y-hidden bg-white">
      <img
        className="mx-auto h-[200px] animate-pulse"
        src={logo}
        alt="loader"
      />
      <p className="font-semibold text-palette-dark">
        Conferma in corso<span className="dots"></span>
      </p>
    </div>
  );
}
