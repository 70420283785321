import { useLocation, useNavigate } from "react-router-dom";
import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { ProcessedQuote } from "../../domain/models/ProcessedQuote";
import { getFormattedDate, getYearFromTimestamp } from "../../utils/dateUtils";
import { ProductCart } from "../../domain/models/ProductCart";
import { AuthRepository } from "../../domain/repository/AuthRepository";
import { useEffect, useRef, useState } from "react";
import ConfirmDialogComponent, {
  DialogType,
} from "../components/admin/ConfirmDialogComponent";
import {
  showSuccessToast,
  showErrorToast,
  showWarningToast,
} from "../../utils/toastUtils";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { useAdminModelController } from "../hook/useAdminModelController";
import { ProductsRepository } from "../../domain/repository";
import { NewConfirmedQuote } from "../../domain/models/NewConfirmedQuote";
import { LoaderView } from "../components/LoaderView";
import { QuoteType } from "../../data/datasource/QuotesDataSourceImpl";
import {
  faMinus,
  faPlus,
  faCartPlus,
  faTrashCan,
  faFloppyDisk,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mailIsValid, phoneIsValid } from "../../utils/fieldUtils";
import DetailQuoteData from "../components/admin/DetailQuoteData";
import CustomTimePicker from "../components/client/CustomTimePicker";
import DatePickerCustom from "../components/DatePickerCustom";
import { DiscountType } from "./PendingQuoteDetailView";
import { ProductData } from "../../domain/models/ProductData";
import { pdf } from "@react-pdf/renderer";
import QuoteEmailDocument from "../components/admin/QuoteEmailDocument";
import { FirebaseEmail } from "../../domain/models/FirebaseEmail";
import { PendingQuote } from "../../domain/models/PendingQuote";
import ExtraInfoQuoteData from "../components/admin/ExtraInfoQuoteData";
import React from "react";

export default function ProcessedQuoteDetailView({
  authRepository,
  productRepository,
  quotesRepository,
}: {
  authRepository: AuthRepository;
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [quote, setQuote] = useState<ProcessedQuote>(location.state.quote);
  const [editQuoteInfo, setEditQuoteInfo] = useState<boolean>(false);
  const [editCart, setEditCart] = useState<boolean>(false);
  const [modifiedQuote, setModifiedQuote] = useState<ProcessedQuote | null>(
    null,
  );

  const [fullNameValid, setFullNameValid] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [phoneValid, setPhoneValid] = useState<boolean>(false);
  const [clientCategory, setClientCategory] = useState<number>(0);
  const [deliveryCategory, setDeliveyCategory] = useState<number>(0);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const [showResults, setShowResults] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [products, setProducts] = useState<ProductCart[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ProductCart[]>([]);

  const [editPrice, setEditPrice] = useState<Record<string, boolean>>({});
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] =
    useState<boolean>(false);
  const [confirmQuoteDialog, setConfirmQuoteDialog] = useState<boolean>(false);
  const [confirmDeleteProductDialog, setConfirmDeleteProductDialog] =
    useState<boolean>(false);
  const [productIdToDelete, setProductIdToDelete] = useState<string>("");
  const {
    handleGetAllProducts,
    handleProcessProcessedQuote,
    handleDeleteQuote,
    handleUpdateQuote,
    handleUploadPDFQuote,
  } = useAdminModelController(productRepository, quotesRepository);

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const loadProducts = async () => {
    const allProducts: ProductData[] = await handleGetAllProducts();

    setProducts(() => {
      return allProducts
        .filter(
          (product) =>
            !quote.cart.some((cartItem) => cartItem.id === product.id),
        )
        .map((product) => ({
          id: product.id,
          title: product.title,
          quantity: 1,
          category: product.category,
          price: product.price,
          image: product.images[0],
        }));
    });
  };

  const toggleEditPrice = (productId: string) => {
    setEditPrice((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const handleChangeProductPrice = (productId: string, newPrice: string) => {
    setQuote((prevQuote) => ({
      ...prevQuote,
      cart: prevQuote.cart.map((item) =>
        item.id === productId ? { ...item, price: parseFloat(newPrice) } : item,
      ),
    }));
  };

  const setQuoteDiscountType = (discountTypeValue: string) => {
    const discountType =
      discountTypeValue === "%" ? DiscountType.Percentage : DiscountType.Amount;
    setQuote((prev) => ({ ...prev, discountType: discountType, discount: 0 }));
  };

  const handleProductQuantityInput = (productId: string, quantity: string) => {
    setQuote((prevQuote) => ({
      ...prevQuote,
      cart: prevQuote.cart.map((item) =>
        item.id === productId ? { ...item, quantity: Number(quantity) } : item,
      ),
    }));

    setFilteredProducts((prevProducts) =>
      prevProducts.map((item) =>
        item.id === productId ? { ...item, quantity: Number(quantity) } : item,
      ),
    );
    setEditCart(true);
  };

  const incrementProductQuantity = (productId: string) => {
    setQuote((prevQuote) => ({
      ...prevQuote,
      cart: prevQuote.cart.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item,
      ),
    }));

    setFilteredProducts((prevProducts) =>
      prevProducts.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item,
      ),
    );
    setEditCart(true);
  };

  const decrementProductQuantity = (productId: string) => {
    setQuote((prevQuote) => ({
      ...prevQuote,
      cart: prevQuote.cart.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity - 1 } : item,
      ),
    }));

    setFilteredProducts((prevProducts) =>
      prevProducts.map((item) =>
        item.id === productId && item.quantity > 0
          ? { ...item, quantity: item.quantity - 1 }
          : item,
      ),
    );
    setEditCart(true);
  };

  const deleteProduct = (productId: string) => {
    setEditCart(true);
    setQuote((prevQuote) => {
      const updatedCart = prevQuote.cart.filter(
        (item) => item.id !== productId,
      );

      const productToAddBack = prevQuote.cart.find(
        (item) => item.id === productId,
      );

      if (productToAddBack) {
        setProducts((prevProducts) => [...prevProducts, productToAddBack]);
      }

      return {
        ...prevQuote,
        cart: updatedCart,
      };
    });
  };

  const onDelete = async () => {
    setShowLoading(true);
    try {
      const result = await handleDeleteQuote(quote.id, QuoteType.Processed);

      if (result) {
        setShowLoading(false);
        showSuccessToast("Il preventivo è stato eliminato!");
        navigate("/dashboard/processedQuotes");
      } else {
        setShowLoading(false);
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
        );
      }
    } catch (error) {
      setShowLoading(false);
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
      );
    }
  };

  const handleEventDateSelect = (date: number, calendarIndex: number) => {
    const selectedTimestamp = new Date(date).getTime();

    setQuote((prevQuote) => {
      const newQuote = { ...prevQuote };

      if (calendarIndex === 1) {
        newQuote.deliveryDate = selectedTimestamp;
        newQuote.eventDate = 0;
        newQuote.returnDate = 0;
      } else if (calendarIndex === 2) {
        newQuote.eventDate = selectedTimestamp;
        newQuote.returnDate = 0;
      } else if (calendarIndex === 3) {
        newQuote.returnDate = selectedTimestamp;
      }

      return newQuote;
    });
  };

  const handleTimeSelect = (
    time: string,
    field: "deliveryTime" | "returnTime",
  ) => {
    setQuote((prevQuote) => ({
      ...prevQuote,
      [field]: time,
    }));
  };

  // const compareQuotes = (
  //   q1: ProcessedQuote | null,
  //   q2: ProcessedQuote,
  // ): boolean => {
  //   if (q1 === null) {
  //     return false;
  //   }

  //   return (
  //     q1.id === q2.id &&
  //     q1.quoteNumber === q2.quoteNumber &&
  //     q1.email === q2.email &&
  //     q1.name === q2.name &&
  //     q1.phone === q2.phone &&
  //     q1.pIVA === q2.pIVA &&
  //     q1.codUnivoco === q2.codUnivoco &&
  //     q1.codFiscale === q2.codFiscale &&
  //     q1.address === q2.address &&
  //     q1.city === q2.city &&
  //     q1.cap === q2.cap &&
  //     q1.text === q2.text &&
  //     q1.date === q2.date &&
  //     compareProductCarts(q1.cart, q2.cart) &&
  //     q1.shippingPrice === q2.shippingPrice &&
  //     q1.shippingAddress === q2.shippingAddress &&
  //     q1.shippingCity === q2.shippingCity &&
  //     q1.shippingCap === q2.shippingCap &&
  //     q1.deliveryDate === q2.deliveryDate &&
  //     q1.deliveryTime === q2.deliveryTime &&
  //     q1.eventDate === q2.eventDate &&
  //     q1.returnDate === q2.returnDate &&
  //     q1.returnTime === q2.returnTime &&
  //     q1.localPickup === q2.localPickup &&
  //     q1.totalPrice === q2.totalPrice &&
  //     q1.pdfUrl === q2.pdfUrl
  //   );
  // };

  // const compareProductCarts = (
  //   cartA: ProductCart[],
  //   cartB: ProductCart[],
  // ): boolean => {
  //   if (cartA.length !== cartB.length) return false;

  //   for (let i = 0; i < cartA.length; i++) {
  //     const productA = cartA[i];
  //     const productB = cartB[i];

  //     if (
  //       productA.id !== productB.id ||
  //       productA.title !== productB.title ||
  //       productA.price !== productB.price ||
  //       productA.quantity !== productB.quantity ||
  //       productA.category !== productB.category ||
  //       productA.image !== productB.image
  //     ) {
  //       return false;
  //     }
  //   }

  //   return true;
  // };

  const startEditing = () => {
    const isNameValid = quote.name.length > 3;
    const isEmailValid = mailIsValid(quote.email);
    const isPhoneValid = phoneIsValid(quote.phone);

    setFullNameValid(isNameValid);
    setEmailValid(isEmailValid);
    setPhoneValid(isPhoneValid);
    setModifiedQuote(quote);
    setEditQuoteInfo(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setQuote((prev) => ({
      ...prev,
      [name]:
        name === "shippingPrice" || name === "discount" ? Number(value) : value,
    }));
  };

  const validateForm = (): boolean => {
    const isNameValid = quote.name.length > 3;
    const isEmailValid = mailIsValid(quote.email);
    const isPhoneValid = phoneIsValid(quote.phone);

    setFullNameValid(isNameValid);
    setEmailValid(isEmailValid);
    setPhoneValid(isPhoneValid);

    return isNameValid && isEmailValid && isPhoneValid;
  };

  const calculateTotalProducts = (): number => {
    let total = 0;
    quote.cart.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });
    return total;
  };

  const calculateTax = (): number => {
    let total = calculateTotalProducts() + quote.shippingPrice;
    return total * 0.22;
  };

  const calculateDiscount = (): number => {
    let partial = calculateTotalProducts() + calculateTax();
    let discountAmount = 0;
    if (quote.discountType === DiscountType.Percentage) {
      if (quote.discount === 0) {
        return 0;
      }
      discountAmount = parseFloat(
        ((partial * quote.discount) / 100).toFixed(2),
      );
    } else if (quote.discountType === DiscountType.Amount) {
      discountAmount = quote.discount;
    }
    return discountAmount;
  };

  const calculateQuoteTotalPrice = (): number => {
    let total: number =
      calculateTotalProducts() +
      calculateTax() +
      quote.shippingPrice -
      calculateDiscount();
    return parseFloat(total.toFixed(2));
  };

  const uploadUpdatedPdf = async (): Promise<string> => {
    const productsTotalPrice = calculateTotalProducts();
    const taxes = calculateTax();
    const discount = calculateDiscount();
    const totalQuotePrice = calculateQuoteTotalPrice();

    const doc = QuoteEmailDocument({
      quote: quote,
      productsTotalPrice: productsTotalPrice,
      taxes: taxes,
      discountValue: discount != null ? discount : 0,
      shippingCost: quote.shippingPrice != null ? quote.shippingPrice : 0,
      total: totalQuotePrice != null ? totalQuotePrice : 0,
    });

    const array: any = [];
    const asPdf = pdf(array);
    asPdf.updateContainer(doc);
    const pdfBlob: Blob = await asPdf.toBlob();

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    const formattedTime =
      currentDate.getHours() + "" + currentDate.getMinutes();

    const fileName = `${quote.name}_${formattedDate}_${formattedTime}.pdf`;

    const pdfFile = new File([pdfBlob], fileName, {
      type: "application/pdf",
    });

    return await handleUploadPDFQuote(quote.email, pdfFile, fileName);
  };

  async function generateEmailContent(
    pdfUrl: string,
    quote: PendingQuote | ProcessedQuote,
  ) {
    let emailHTML = `
      <div style="font-family: Arial, sans-serif; color: #333; background-color: #f9f9f9; padding: 20px; border-radius: 8px;">
        
        <p style="font-size: 16px; line-height: 1.5; color: #333;">
        Gentile Cliente,<br><br>
        Le inviamo il preventivo aggiornato in base alle modifiche richieste. <br />
        In allegato troverà la nuova versione del preventivo.  <br />
        Se le condizioni proposte sono di suo gradimento, può procedere con l'acquisto effettuando il pagamento tramite bonifico bancario. <br /> 
        <strong>In caso di necessità di ulteriori modifiche o chiarimenti, non esiti a contattarci: saremo felici di adattare il preventivo alle sue esigenze.</strong>
        </p>

        ${
          quote.text
            ? `<p style="font-size: 16px; line-height: 1.5; color: #333; background-color: #eef4f7; padding: 10px; border-left: 4px solid #5B4934;">
          <strong>Nota per il cliente:</strong><br>
          ${quote.text}
        </p>`
            : ""
        }
  
        <p style="font-size: 16px; line-height: 1.5; color: #333;">
          <strong>Dettagli per il pagamento:</strong><br>
          <strong>IBAN:</strong> <span style="color: #5B4934; font-weight: bold;">IT91F0883346322000070102924</span><br>
          <strong>Intestatario:</strong> Eventi & Sapori Srl<br>
          <strong>Causale:</strong> ${quote.quoteNumber}/${getYearFromTimestamp(quote.date)} - ${getFormattedDate(quote.date)}
        </p>
  
        <div style="margin-top: 20px; text-align: center;">
          <a href="${pdfUrl}" target="_blank" 
            style="display: inline-block; background-color: #5B4934; color: #fff; text-decoration: none; 
                   font-size: 16px; padding: 12px 20px; border-radius: 5px; font-weight: bold;">
            📄 Visualizza il Preventivo Aggiornato
          </a>
        </div>
  
        <hr style="margin: 30px 0; border: none; border-top: 1px solid #ddd;">
  
        <div style="font-size: 14px; color: #555; text-align: center; line-height: 1.6;">
          <strong style="font-size: 16px; color: #333;">Eventi & Sapori Srl</strong><br>
          📍 <span style="color: #5B4934;">Via Macallè, 33/A, 12045 Fossano CN</span><br>
          📞 <a href="tel:+393474689592" style="color: #5B4934; text-decoration: none; font-weight: bold;">+39 347 468 9592</a><br>
          📧 <a href="mailto:daprent.preventivi@gmail.com" style="color: #5B4934; text-decoration: none; font-weight: bold;">daprent.preventivi@gmail.com</a><br>
          🏛 <strong>P.IVA:</strong> 03740990043
        </div>
      </div>
    `;

    return emailHTML;
  }

  // const uploadAndSendNewPdf = async () => {
  //   let newPdfUrl = await uploadUpdatedPdf();
  //   const updatedQuote = { ...quote, pdfUrl: newPdfUrl };
  //   setQuote(updatedQuote);
  //   const html = await generateEmailContent(newPdfUrl, updatedQuote);
  //   let firebaseEmail: FirebaseEmail = {
  //     to: updatedQuote.email,
  //     message: {
  //       subject: "Il tuo preventivo di DapRent aggiornato è pronto!",
  //       html: html,
  //     },
  //   };
  //   setLastSentQuote(updatedQuote);
  //   const result = await handleUpdateQuote(
  //     updatedQuote,
  //     QuoteType.Processed,
  //     firebaseEmail,
  //   );
  //   if (result) {
  //     showSuccessToast("Email inviata");
  //   } else {
  //     showErrorToast("Errore durante l'invio della mail");
  //   }
  // };

  const applyChanges = async () => {
    if (validateForm()) {
      setModifiedQuote(null);
      setEditQuoteInfo(false);
      setEditCart(false);
      let newPdfUrl = await uploadUpdatedPdf();
      let total = calculateQuoteTotalPrice();
      setQuote((prev) => ({
        ...prev,
        pdfUrl: newPdfUrl,
        totalPrice: total,
      }));
      const html = await generateEmailContent(newPdfUrl, quote);
      let firebaseEmail: FirebaseEmail = {
        to: quote.email,
        message: {
          subject: "Il tuo preventivo di DapRent aggiornato è pronto!",
          html: html,
        },
      };
      const result = await handleUpdateQuote(
        quote,
        QuoteType.Processed,
        firebaseEmail,
      );
      if (result) {
        showSuccessToast("Preventivo aggiornato e inviato al cliente");
      } else {
        showErrorToast("Errore durante l'aggiornamento");
      }
    }
  };

  const discardChanges = () => {
    if (modifiedQuote != null) {
      setQuote(modifiedQuote);
      setModifiedQuote(null);
    }
    setEditQuoteInfo(false);
  };

  const saveCart = async () => {
    if (!editQuoteInfo || validateForm()) {
      setModifiedQuote(null);
      setEditCart(false);
      setEditQuoteInfo(false);
      let newPdfUrl = await uploadUpdatedPdf();
      let total = calculateQuoteTotalPrice();
      setQuote((prev) => ({
        ...prev,
        pdfUrl: newPdfUrl,
        totalPrice: total,
      }));
      const html = await generateEmailContent(newPdfUrl, quote);
      let firebaseEmail: FirebaseEmail = {
        to: quote.email,
        message: {
          subject: "Il tuo preventivo di DapRent aggiornato è pronto!",
          html: html,
        },
      };
      const result = await handleUpdateQuote(
        quote,
        QuoteType.Processed,
        firebaseEmail,
      );
      if (result) {
        showSuccessToast("Preventivo aggiornato e inviato al cliente");
      } else {
        showErrorToast("Errore durante l'aggiornamento");
      }
    }
  };

  const addProductToQuoteCart = (product: ProductCart) => {
    setEditCart(true);
    setQuote((prevQuote) => {
      const existingProduct = prevQuote.cart.find(
        (item) => item.id === product.id,
      );

      const updatedCart = existingProduct
        ? prevQuote.cart.map((item) =>
            item.id === product.id
              ? { ...item, quantity: item.quantity + 1 }
              : item,
          )
        : [...prevQuote.cart, { ...product }];

      return {
        ...prevQuote,
        cart: updatedCart,
      };
    });

    setProducts((prevProducts) =>
      prevProducts.filter((p) => p.id !== product.id),
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      resultsRef.current &&
      !resultsRef.current.contains(event.target as Node) &&
      searchInputRef.current &&
      !searchInputRef.current.contains(event.target as Node)
    ) {
      setShowResults(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearch(query);

    if (query.trim() === "") {
      setFilteredProducts([]);
      setShowResults(false);
      return;
    }

    const filtered = products
      .filter((product) => {
        const title = product.title.toLowerCase();
        return (
          title.startsWith(query) ||
          title.split(" ").some((word) => word.startsWith(query))
        );
      })
      .sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        if (titleA.startsWith(query) && !titleB.startsWith(query)) return -1;
        if (!titleA.startsWith(query) && titleB.startsWith(query)) return 1;
        return titleA.localeCompare(titleB);
      });

    setFilteredProducts(filtered);
    setShowResults(true);
  };

  const onConfirm = async () => {
    setShowLoading(true);
    setConfirmQuoteDialog(false);

    let processedQuote: NewConfirmedQuote = {
      quoteNumber: quote.quoteNumber,
      email: quote.email,
      name: quote.name,
      phone: quote.phone,
      pIVA: quote.pIVA,
      codUnivoco: quote.codUnivoco,
      codFiscale: quote.codFiscale,
      address: quote.address,
      city: quote.city,
      cap: quote.cap,
      text: quote.text,
      date: Date.now(),
      cart: quote.cart,
      shippingPrice: quote.shippingPrice,
      discount: quote.discount,
      discountType: quote.discountType,
      shippingAddress: quote.shippingAddress,
      shippingCity: quote.shippingCity,
      shippingCap: quote.shippingCap,
      deliveryDate: quote.deliveryDate,
      deliveryTime: quote.deliveryTime,
      eventDate: quote.eventDate,
      returnDate: quote.returnDate,
      returnTime: quote.returnTime,
      localPickup: quote.localPickup,
      totalPrice: quote.totalPrice,
      pdfUrl: quote.pdfUrl,
    };

    try {
      const result = await handleProcessProcessedQuote(
        quote.id,
        processedQuote,
      );

      if (result) {
        setShowLoading(false);
        showSuccessToast("Il preventivo è stato confermato!");
        navigate("/dashboard/processedQuotes");
      } else {
        setShowLoading(false);
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
        );
      }
    } catch (error) {
      setShowLoading(false);
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
      );
    }
  };

  if (showLoading) {
    return <LoaderView />;
  }
  return (
    <div className="min-h-full w-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <main className="mx-auto w-full px-6 md:px-24">
        <div className="my-4 justify-between border-b border-gray-200 md:my-10">
          <div className="my-3 flex items-center justify-between text-xs sm:text-base">
            <h1 className="text-xl font-bold tracking-tight text-gray-900 md:text-4xl">
              Preventivo {quote.quoteNumber}/{getYearFromTimestamp(quote.date)}
            </h1>
            <div className="flex space-x-2">
              {!editQuoteInfo ? (
                <>
                  <button
                    type="button"
                    className="rounded-md bg-slate-500 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-slate-700"
                    onClick={() => window.open(quote.pdfUrl, "_blank")}
                  >
                    Visualizza
                  </button>
                  <button
                    type="button"
                    className="rounded-md bg-palette-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark"
                    onClick={startEditing}
                  >
                    Modifica
                  </button>

                  <button
                    type="button"
                    className="rounded-md bg-red-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-red-800"
                    onClick={() => setConfirmDeleteDialog(true)}
                  >
                    Elimina
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="w-20 rounded-md bg-palette-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark"
                    onClick={applyChanges}
                  >
                    Salva
                  </button>
                  <button
                    type="button"
                    className="w-20 rounded-md bg-gray-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-gray-800"
                    onClick={discardChanges}
                  >
                    Annulla
                  </button>
                </>
              )}
            </div>
          </div>

          {editQuoteInfo ? (
            <div className="mb-6 rounded-lg bg-gray-50 px-2 py-6 sm:px-10 xl:mt-0 xl:rounded-b-lg xl:rounded-t-none">
              <div className="">
                <label
                  htmlFor="full-name"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Nome e cognome
                </label>
                <div className="relative">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className={`w-full rounded-md border ${
                      !fullNameValid ? "border-red-500" : "border-gray-200"
                    } px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                    placeholder="Mario Rossi"
                    onChange={handleChange}
                    value={quote.name}
                  />
                </div>
                {!fullNameValid && (
                  <span className="text-xs text-red-500">
                    Compila questo campo
                  </span>
                )}
                <label
                  htmlFor="email"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Email
                </label>
                <div className="relative">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className={`w-full rounded-md border ${
                      !emailValid ? "border-red-500" : "border-gray-200"
                    } px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                    placeholder="your.email@gmail.com"
                    onChange={handleChange}
                    value={quote.email}
                  />
                </div>
                {!emailValid && (
                  <span className="text-xs text-red-500">
                    Inserisci un valore valido
                  </span>
                )}
                <label
                  htmlFor="phone"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Telefono
                </label>
                <div className="relative">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className={`w-full rounded-md border ${
                      !phoneValid ? "border-red-500" : "border-gray-200"
                    } px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                    placeholder="333 3333 333"
                    onChange={handleChange}
                    value={quote.phone}
                  />
                </div>
                {!phoneValid && (
                  <span className="text-xs text-red-500">
                    Inserisci un valore valido
                  </span>
                )}

                <label
                  htmlFor="category"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Categoria
                </label>
                <div className="relative flex rounded-lg border bg-gray-200 shadow">
                  <div
                    className={`absolute bottom-0 top-0 my-0.5 w-1/2 rounded-lg bg-white shadow transition-all duration-300 ${
                      clientCategory === 0 ? "left-0" : "left-1/2"
                    }`}
                  />

                  <button
                    onClick={() => setClientCategory(0)}
                    className={`z-10 w-1/2 py-2 text-center font-medium transition-colors duration-300 ${
                      clientCategory === 0 ? "text-black" : "text-gray-500"
                    }`}
                  >
                    Azienda
                  </button>

                  <button
                    onClick={() => setClientCategory(1)}
                    className={`z-10 w-1/2 py-2 text-center font-medium transition-colors duration-300 ${
                      clientCategory === 1 ? "text-black" : "text-gray-500"
                    }`}
                  >
                    Privato
                  </button>
                </div>

                {clientCategory === 0 ? (
                  <div className="flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="full-name"
                        className="mb-2 mt-4 block text-sm font-medium"
                      >
                        Partita IVA
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          id="pIVA"
                          name="pIVA"
                          className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="###"
                          onChange={handleChange}
                          value={quote.pIVA ?? ""}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="codice-univoco"
                        className="mb-2 mt-4 block text-sm font-medium"
                      >
                        Codice Univoco
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          id="full-name"
                          name="codUnivoco"
                          className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="###"
                          onChange={handleChange}
                          value={quote.codUnivoco ?? ""}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <label
                      htmlFor="full-name"
                      className="mb-2 mt-4 block text-sm font-medium"
                    >
                      Codice Fiscale
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="codFiscale"
                        name="codFiscale"
                        className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                        placeholder="###"
                        onChange={handleChange}
                        value={quote.codFiscale ?? ""}
                      />
                    </div>
                  </div>
                )}

                <label
                  htmlFor="address"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Indirizzo di fatturazione
                </label>

                <div className="grid grid-cols-5 gap-x-2">
                  <div className="col-span-3">
                    <input
                      type="text"
                      id="address"
                      name="address"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      placeholder="Via/Corso"
                      onChange={handleChange}
                      value={quote.address}
                    />
                  </div>

                  <div>
                    <input
                      type="text"
                      name="city"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      placeholder="Città"
                      onChange={handleChange}
                      value={quote.city}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="cap"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      placeholder="CAP"
                      onChange={handleChange}
                      value={quote.cap}
                    />
                  </div>
                </div>

                <label
                  htmlFor="category"
                  className="mb-2 mt-4 block text-sm font-medium"
                >
                  Metodo di consegna
                </label>

                <div className="relative flex rounded-lg border bg-gray-200 shadow">
                  <div
                    className={`absolute bottom-0 top-0 my-0.5 w-1/2 rounded-lg bg-white shadow transition-all duration-300 ${
                      deliveryCategory === 0 ? "left-0" : "left-1/2"
                    }`}
                  />

                  <button
                    onClick={() => {
                      setDeliveyCategory(0);
                      setQuote({ ...quote, localPickup: true });
                    }}
                    className={`z-10 w-1/2 py-2 text-center font-medium transition-colors duration-300 ${
                      deliveryCategory === 0 ? "text-black" : "text-gray-500"
                    }`}
                  >
                    Ritiro in loco
                  </button>

                  <button
                    onClick={() => {
                      setDeliveyCategory(1);
                      setQuote({ ...quote, localPickup: false });
                    }}
                    className={`z-10 w-1/2 py-2 text-center font-medium transition-colors duration-300 ${
                      deliveryCategory === 1 ? "text-black" : "text-gray-500"
                    }`}
                  >
                    Spedizione
                  </button>
                </div>

                {deliveryCategory === 1 ? (
                  <div>
                    <label
                      htmlFor="shipping-address"
                      className="mb-2 mt-4 block text-sm font-medium"
                    >
                      Indirizzo di spedizione
                    </label>
                    <div className="grid grid-cols-5 gap-x-2">
                      <div className="col-span-3">
                        <input
                          type="text"
                          id="shippingAddress"
                          name="shippingAddress"
                          className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="Via/Corso"
                          onChange={handleChange}
                          value={quote.shippingAddress}
                        />
                      </div>

                      <div>
                        <input
                          type="text"
                          name="shippingCity"
                          className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="Città"
                          onChange={handleChange}
                          value={quote.shippingCity}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          name="shippingCap"
                          className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="CAP"
                          onChange={handleChange}
                          value={quote.shippingCap}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="grid grid-cols-5 gap-x-2">
                  <div className="relative col-span-3 overflow-visible">
                    <label
                      htmlFor="delivery-date"
                      className="mb-2 mt-4 block text-sm font-medium"
                    >
                      Data consegna
                    </label>
                    <div className="w-full overflow-visible">
                      <DatePickerCustom
                        onDateSelect={(date) =>
                          handleEventDateSelect(date.getTime(), 1)
                        }
                        {...(quote.deliveryDate && {
                          internalDate: new Date(quote.deliveryDate),
                        })}
                        minDate={
                          quote.eventDate ? new Date(quote.eventDate) : null
                        }
                      />
                    </div>
                  </div>
                  <div className="relative col-span-2 overflow-visible">
                    <label
                      htmlFor="delivery-date"
                      className="mb-2 mt-4 block text-sm font-medium"
                    >
                      Ora consegna
                    </label>
                    <div className="w-full overflow-visible">
                      <CustomTimePicker
                        onTimeSelect={(time) =>
                          handleTimeSelect(time, "deliveryTime")
                        }
                        {...(quote.deliveryTime && {
                          internalTime: quote.deliveryTime,
                        })}
                      />
                    </div>
                  </div>
                </div>

                <div className="relative overflow-visible">
                  <label
                    htmlFor="event-date"
                    className="mb-2 mt-4 block text-sm font-medium"
                  >
                    Data evento
                  </label>
                  <div className="w-full overflow-visible">
                    <DatePickerCustom
                      onDateSelect={(date) =>
                        handleEventDateSelect(date.getTime(), 2)
                      }
                      {...(quote.eventDate && {
                        internalDate: new Date(quote.eventDate),
                      })}
                      minDate={
                        quote.deliveryDate ? new Date(quote.deliveryDate) : null
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-5 gap-x-2">
                  <div className="relative col-span-3 overflow-visible">
                    <label
                      htmlFor="return-date"
                      className="mb-2 mt-4 block text-sm font-medium"
                    >
                      Data riconsegna
                    </label>
                    <div className="w-full overflow-visible">
                      <DatePickerCustom
                        onDateSelect={(date) =>
                          handleEventDateSelect(date.getTime(), 3)
                        }
                        {...(quote.returnDate && {
                          internalDate: new Date(quote.returnDate),
                        })}
                        minDate={
                          quote.eventDate ? new Date(quote.deliveryDate) : null
                        }
                      />
                    </div>
                  </div>
                  <div className="relative col-span-2 overflow-visible">
                    <label
                      htmlFor="delivery-date"
                      className="mb-2 mt-4 block text-sm font-medium"
                    >
                      Ora riconsegna
                    </label>
                    <div className="w-full overflow-visible">
                      <CustomTimePicker
                        onTimeSelect={(time) =>
                          handleTimeSelect(time, "returnTime")
                        }
                        {...(quote.returnTime && {
                          internalTime: quote.returnTime,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <DetailQuoteData quote={quote} />
          )}

          <div className="mt-10 flex items-center justify-between">
            <label
              htmlFor="cart"
              className="block text-2xl font-bold leading-6 text-gray-900"
            >
              Carrello
            </label>
            {editCart ? (
              <button
                type="button"
                className="rounded-md bg-palette-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark"
                onClick={saveCart}
              >
                Salva
              </button>
            ) : null}
          </div>
          <div className="mt-2">
            {/* Search Bar */}
            <div className="relative">
              <input
                ref={searchInputRef}
                type="text"
                name="search"
                id="search"
                value={search}
                placeholder="Cerca prodotto..."
                onChange={handleSearchChange}
                onFocus={() => {
                  if (search.trim() !== "") setShowResults(true);
                }}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
              />
              {showResults && search !== "" && (
                <div
                  ref={resultsRef}
                  className="absolute left-0 top-full z-50 mt-1 max-h-64 w-full overflow-y-auto rounded-md bg-gray-200 shadow-lg"
                >
                  <ul className="p-2">
                    {filteredProducts.length > 0 ? (
                      filteredProducts.map((product: ProductCart, index) => (
                        <li
                          key={`${product.id}-${index}`}
                          className="border-b pb-2 last:pb-0"
                        >
                          <div className="flex items-center justify-between rounded-lg bg-white p-3 sm:flex-row">
                            {/* Colonna Immagine + Titolo */}
                            <div className="flex w-1/3 min-w-0 flex-col items-center justify-center sm:flex-row sm:justify-start">
                              <img
                                className="h-16 w-16 rounded-md border object-contain object-center"
                                src={product.image}
                                alt="product"
                              />
                              <span className="truncate text-sm font-semibold sm:ml-4 sm:text-lg">
                                {product.title}
                              </span>
                            </div>

                            {/* Colonna Quantità */}
                            <div className="flex w-1/6 justify-center">
                              <button
                                type="button"
                                disabled={product.quantity < 2}
                                className={`h-8 w-8 rounded-md px-2 text-sm font-semibold text-white shadow-sm ${product.quantity < 2 ? "cursor-not-allowed bg-gray-400" : "bg-palette-primary hover:bg-gray-800"}`}
                                onClick={() =>
                                  decrementProductQuantity(product.id)
                                }
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </button>

                              <input
                                type="number"
                                inputMode="numeric"
                                id="quantity"
                                name="quantity"
                                min="1"
                                step="1"
                                value={product.quantity}
                                onChange={(e) =>
                                  handleProductQuantityInput(
                                    product.id,
                                    e.target.value,
                                  )
                                }
                                className="form-input mx-2 h-8 w-20 appearance-auto rounded-md border border-gray-300 text-center text-gray-900 focus:border-palette-light focus:ring-palette-light"
                              />
                              <button
                                type="button"
                                className="h-8 w-8 rounded-md bg-palette-primary px-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800"
                                onClick={() =>
                                  incrementProductQuantity(product.id)
                                }
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </button>
                            </div>

                            {/* Colonna Prezzo + Aggiungi */}
                            <div className="flex w-1/3 items-center justify-end space-x-1 sm:space-x-6">
                              <p className="text-sm font-bold sm:text-lg">
                                {product.price.toFixed(2).replace(".", ",")}€
                              </p>
                              <button
                                type="button"
                                className="flex items-center rounded-md bg-palette-primary px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800"
                                onClick={() => addProductToQuoteCart(product)}
                              >
                                <FontAwesomeIcon
                                  icon={faCartPlus}
                                  className="sm:mr-2"
                                />
                                <p className="hidden sm:block"> Aggiungi</p>
                              </button>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <div>Nessun risultato</div>
                    )}
                  </ul>
                </div>
              )}
            </div>

            {/* Carrello */}
            <div
              className="mt-6 w-full space-y-3 rounded-lg border border-x-gray-300 bg-white px-2 py-4 sm:px-6"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              {quote.cart.length > 0 ? (
                quote.cart.map((product: ProductCart) => (
                  <div
                    className="flex items-center rounded-lg bg-white sm:flex-row"
                    key={product.id}
                  >
                    <button
                      aria-label="toggle-edit-price"
                      onClick={() => {
                        setProductIdToDelete(product.id);
                        setConfirmDeleteProductDialog(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className="mr-4 inline-flex w-4 text-red-950"
                      />
                    </button>

                    <img
                      className="m-2 h-24 w-24 flex-shrink-0 rounded-md border object-contain object-center"
                      src={product.image}
                      alt="product"
                    />

                    <div className="flex w-full min-w-0 flex-col justify-center space-y-2 px-4 py-4">
                      <span className="truncate text-lg font-semibold">
                        {product.title}
                      </span>
                      <div className="flex">
                        <button
                          type="button"
                          disabled={product.quantity < 2}
                          className={`h-8 w-8 rounded-md px-2 text-sm font-semibold text-white shadow-sm ${product.quantity < 2 ? "cursor-not-allowed bg-gray-400" : "bg-palette-primary hover:bg-gray-800"}`}
                          onClick={() => decrementProductQuantity(product.id)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>

                        <input
                          type="number"
                          inputMode="numeric"
                          id="quantity"
                          name="quantity"
                          min="1"
                          step="1"
                          value={product.quantity}
                          onChange={(e) =>
                            handleProductQuantityInput(
                              product.id,
                              e.target.value,
                            )
                          }
                          className="form-input mx-2 h-8 w-20 appearance-auto rounded-md border border-gray-300 text-center text-gray-900 focus:border-palette-light focus:ring-palette-light"
                        />
                        <button
                          type="button"
                          className="h-8 w-8 rounded-md bg-palette-primary px-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800"
                          onClick={() => incrementProductQuantity(product.id)}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    </div>

                    <div className="flex items-center space-x-3">
                      {editPrice[product.id] ? (
                        <input
                          type="number"
                          value={product.price.toFixed(2)}
                          onChange={(e) =>
                            handleChangeProductPrice(product.id, e.target.value)
                          }
                          className="w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                        />
                      ) : (
                        <p className="text-lg font-bold">
                          {product.price.toFixed(2).replace(".", ",")}€
                        </p>
                      )}
                      <button onClick={() => toggleEditPrice(product.id)}>
                        <FontAwesomeIcon
                          icon={
                            editPrice[product.id] ? faFloppyDisk : faPenToSquare
                          }
                          className="inline-flex w-4"
                          style={{ color: "#97815C" }}
                        />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="font-semibold">
                  Non hai ancora aggiunto nessun prodotto al carrello
                </p>
              )}
            </div>
          </div>
          <div className="mt-10">
            {editQuoteInfo ? (
              <div>
                <div className="col-span-full mt-10">
                  <label
                    htmlFor="description"
                    className="block border-gray-300 text-sm font-medium leading-6 text-gray-900"
                  >
                    Testo della mail
                  </label>
                  <div className="mt-2">
                    <textarea
                      name="text"
                      rows={3}
                      id="text"
                      value={quote.text}
                      onChange={handleChange}
                      className="mt-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="col-span-full mt-10 w-full">
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Costi di spedizione
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="shippingPrice"
                      id="shippingPrice"
                      value={quote.shippingPrice}
                      placeholder="€"
                      onChange={handleChange}
                      className="block w-[150px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                    />
                  </div>
                  <label
                    htmlFor="price"
                    className="mt-6 block text-sm font-medium leading-6 text-gray-900"
                  >
                    Sconto
                  </label>
                  <div className="relative mt-2 w-[150px] rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center"></div>
                    <input
                      type="number"
                      name="discount"
                      id="discount"
                      className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="0"
                      value={quote.discount}
                      onChange={handleChange}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center">
                      <label htmlFor="currency" className="sr-only">
                        Sconto
                      </label>
                      <select
                        id="discountType"
                        name="discountType"
                        className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                        onChange={(e) => setQuoteDiscountType(e.target.value)}
                      >
                        <option value="%">%</option>
                        <option value="€">€</option>
                      </select>
                    </div>
                  </div>
                  <div className="mx-auto mt-10 rounded-lg bg-neutral-100 p-4 lg:w-2/3">
                    <div className="mt-2 flex items-center justify-between">
                      <p className="text-base font-medium text-gray-900">
                        Prodotti
                      </p>
                      <p className="text-sm font-semibold text-gray-900">
                        {calculateTotalProducts()} €
                      </p>
                    </div>
                    <div className="mt-2 flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-900">
                        Costi spedizione
                      </p>
                      <p className="text-sm font-semibold text-gray-900">
                        {quote.shippingPrice} €
                      </p>
                    </div>
                    <div className="mt-2 flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-900">
                        Iva 22%
                      </p>
                      <p className="text-sm font-semibold text-gray-900">
                        {calculateTax()} €
                      </p>
                    </div>
                    <div className="mt-2 flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-900">
                        Sconto
                      </p>
                      <p className="text-sm font-semibold text-gray-900">
                        {calculateDiscount()} €
                      </p>
                    </div>
                    <div className="my-4 border-t border-gray-300"></div>
                    <div className="mt-1 flex items-center justify-between">
                      <p className="text-lg font-bold text-gray-900">Totale</p>
                      <p className="text-2xl font-semibold text-gray-900">
                        {calculateQuoteTotalPrice()} €
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <ExtraInfoQuoteData quote={quote} />
            )}
          </div>

          <button
            type="button"
            className="mb-10 ml-auto mt-10 flex rounded-md bg-palette-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark"
            onClick={() => {
              if (quote.cart.length > 0) {
                setConfirmQuoteDialog(true);
              } else {
                showWarningToast("Carrello vuoto, impossibile confermare");
              }
            }}
          >
            Conferma preventivo
          </button>
        </div>
        <ConfirmDialogComponent
          type={DialogType.Delete}
          title="Elimina preventivo"
          body="Sei sicuro di voler eliminare questo preventivo? Questa azione non può essere annullata."
          open={confirmDeleteDialog}
          onClose={() => setConfirmDeleteDialog(false)}
          onConfirm={onDelete}
        />
        <ConfirmDialogComponent
          type={DialogType.Confirm}
          title="Conferma preventivo"
          body="Sei sicuro di voler confermare questo preventivo? Il cliente riceverà una email con il riepilogo dei prodotti e dei costi totali."
          open={confirmQuoteDialog}
          onClose={() => setConfirmQuoteDialog(false)}
          onConfirm={onConfirm}
        />
        <ConfirmDialogComponent
          type={DialogType.Confirm}
          title="Elimina prodotto"
          body="Sei sicuro di voler eliminare questo prodotto? Dovrai aggiungerlo nuovamente tramite la ricerca."
          open={confirmDeleteProductDialog}
          onClose={() => setConfirmDeleteProductDialog(false)}
          onConfirm={() => {
            deleteProduct(productIdToDelete);
            setConfirmDeleteProductDialog(false);
          }}
        />
      </main>
      <FooterComponent />
    </div>
  );
}
