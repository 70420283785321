import Lottie from "react-lottie";

function AnimationComponent({
  animation,
  message,
}: {
  animation: any;
  message: string;
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="flex h-96 flex-col items-center justify-center">
      <Lottie options={defaultOptions} height={300} width={300} />
      <p className="text-lg text-palette-dark">{message}</p>
    </div>
  );
}

export default AnimationComponent;
