import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useClientModelController } from "../hook/useClientModelController";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { ProductData } from "../../domain/models/ProductData";
import { ProductDetailComponent } from "../components/admin/ProductDetailComponent";
import { AuthRepository } from "../../domain/repository/AuthRepository";
import { BackToProductButton } from "../components/client/BackToProductButton";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmDialogComponent, {
  DialogType,
} from "../components/admin/ConfirmDialogComponent";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { useAdminModelController } from "../hook/useAdminModelController";
import { Set } from "../../domain/models/Set";
import AnimationComponent from "../components/AnimationComponent";
import emptySetAnimation from "../../assets/lotties/empty_set.json";
import EditSetDialogComponent from "../components/admin/EditSetDialogComponent";
import { getCategoryFromFullPath } from "../../utils/productUtils";
import { LoaderView } from "../components/LoaderView";

const atcBtnStyle = `py-3 bg-palette-primary text-white w-full mt-8 rounded-lg font-medium text-xl flex 
justify-center items-baseline hover:bg-palette-dark`;

export function ProductDetailView({
  authRepository,
  productRepository,
  quotesRepository,
}: {
  authRepository: AuthRepository;
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();

  const { handleGetAllProducts, handleGetAllSets } = useClientModelController(
    productRepository,
    quotesRepository,
  );
  const { productId } = useParams();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentSet, setCurrentSet] = useState<Set | null>(location.state.set);
  const [editSetDialogOpen, setEditSetDialogOpen] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductData | null>(null);
  const [productEdited, setProductEdited] = useState<boolean>(true);

  const [productsFromSet, setProductsFromSet] = useState<ProductData[] | null>(
    null,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSet, setIsSet] = useState<boolean>(
    currentSet !== null && currentSet !== undefined,
  );
  const [allSets, setAllSets] = useState<Set[]>([]);
  const [confirmDeleteDialog, setConfirmDeleteDialog] =
    useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const loadProduct = async () => {
    const products: ProductData[] = await handleGetAllProducts();
    const currentProduct =
      products.find((item) => item.id === productId) ?? null;
    setProduct(currentProduct);
    const sets: Set[] | null = await handleGetAllSets();
    setAllSets(sets ?? []);
    if (currentProduct === null) {
      const currentProductsFromSet =
        products.filter(
          (item) =>
            getCategoryFromFullPath(item.category).toLowerCase() ===
            productId?.toLowerCase(),
        ) ?? null;
      if (
        currentProductsFromSet !== null &&
        currentProductsFromSet.length > 0
      ) {
        setProductsFromSet(currentProductsFromSet);
      }
    }
  };

  useEffect(() => {
    if (productEdited) {
      loadProduct();
      setProductEdited(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEdited]);

  const goBack = () => {
    navigate("/dashboard");
  };

  const { handleDeleteProduct, handleDeleteSet } = useAdminModelController(
    productRepository,
    quotesRepository,
  );

  const deleteProduct = async (product: ProductData) => {
    const result = await handleDeleteProduct(product);
    return result;
  };

  const deleteSet = async () => {
    try {
      setShowLoader(true);

      if (productsFromSet) {
        for (const product of productsFromSet) {
          const result = await deleteProduct(product);
          if (!result) {
            showErrorToast(
              "Qualcosa è andato storto durante l'eliminazione di un prodotto. Ti preghiamo di riprovare più tardi.",
            );
            setShowLoader(false);
            return;
          }
        }
      }

      const result = await handleDeleteSet(currentSet!);

      if (result) {
        showSuccessToast("Il set è stato eliminato con successo!");
        navigate("/dashboard");
      } else {
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
        );
      }

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
      );
    }
  };

  if (showLoader) {
    return <LoaderView />;
  }

  return (
    <>
      <div className="min-h-full w-full">
        <DashboardHeaderComponent authRepository={authRepository} />
        {isSet ? (
          <>
            <div className="mx-auto flex w-11/12 max-w-2xl flex-col items-center justify-center py-6 md:flex-row md:items-start md:space-x-8 md:space-y-0 lg:space-x-12">
              <BackToProductButton goBack={goBack} />
              <button
                className={atcBtnStyle}
                aria-label="cart-button"
                onClick={() => setEditSetDialogOpen(true)}
              >
                Modifica set
                <FontAwesomeIcon icon={faEdit} className="my-auto ml-2 w-5" />
              </button>
              <button
                className={atcBtnStyle}
                aria-label="cart-button"
                onClick={() => setConfirmDeleteDialog(true)}
              >
                Elimina set
                <FontAwesomeIcon icon={faTrash} className="my-auto ml-2 w-5" />
              </button>
            </div>
            <div className="flex items-center justify-center">
              <span className="inline-flex items-center rounded-md bg-yellow-50 px-4 py-1 text-2xl font-semibold text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                {currentSet?.name}
              </span>
            </div>

            {productsFromSet === null ? (
              <div className="mt-20">
                <AnimationComponent
                  animation={emptySetAnimation}
                  message="Non ci sono prodotti"
                />
              </div>
            ) : null}

            {productsFromSet?.map((product) => (
              <div className="mx-auto flex w-11/12 max-w-6xl flex-col items-center justify-center space-y-8 py-24 md:flex-row md:items-start md:space-x-8 md:space-y-0 lg:space-x-24">
                <ProductDetailComponent
                  key={product.id}
                  productRepository={productRepository}
                  quotesRepository={quotesRepository}
                  product={product}
                  isSet={true}
                  allSets={allSets}
                  productEdited={() => {
                    setProductEdited(true);
                    navigate(-1);
                  }}
                />
              </div>
            ))}
          </>
        ) : (
          <div className="mx-auto flex w-11/12 max-w-6xl flex-col items-center justify-center space-y-8 py-24 md:flex-row md:items-start md:space-x-8 md:space-y-0 lg:space-x-24">
            {product && (
              <ProductDetailComponent
                productRepository={productRepository}
                quotesRepository={quotesRepository}
                product={product}
                isSet={false}
                allSets={allSets}
                productEdited={() => {
                  setProductEdited(true);
                  navigate(-1);
                }}
              />
            )}
          </div>
        )}
      </div>
      <ConfirmDialogComponent
        type={DialogType.Delete}
        title="Elimina set"
        body="Sei sicuro di voler eliminare il set? Anche tutti i
                          prodotti al suo interno verranno eliminati
                          definitivamente. Questa azione non può essere
                          annullata."
        open={confirmDeleteDialog}
        onClose={() => setConfirmDeleteDialog(false)}
        onConfirm={deleteSet}
      />
      {currentSet !== null && currentSet !== undefined ? (
        <EditSetDialogComponent
          productRepository={productRepository}
          quotesRepository={quotesRepository}
          set={currentSet!}
          allProducts={productsFromSet!}
          open={editSetDialogOpen}
          onClose={() => {
            setEditSetDialogOpen(false);
          }}
          onConfirm={() => {
            setEditSetDialogOpen(false);
            navigate("/dashboard");
          }}
        />
      ) : null}
    </>
  );
}
