import { useEffect, useState } from "react";

const CustomTimePicker = ({
  internalTime,
  onTimeSelect,
}: {
  internalTime?: string;
  onTimeSelect: (time: string) => void;
}) => {
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const times: string[] = [];
  for (let hour = 0; hour <= 23; hour++) {
    times.push(`${hour.toString().padStart(2, "0")}:00`);
    times.push(`${hour.toString().padStart(2, "0")}:30`);
  }

  useEffect(() => {
    if (internalTime) {
      setSelectedTime(internalTime);
    }
  }, [internalTime]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest(".time-picker")) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <div className="time-picker">
      <input
        type="text"
        className="block w-full cursor-pointer rounded-md border border-gray-300 bg-white p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
        placeholder="Seleziona un orario"
        value={selectedTime}
        readOnly
        onClick={() => setIsOpen(!isOpen)}
      />

      {isOpen && (
        <div className="absolute left-0 z-10 mt-2 max-h-60 w-full overflow-auto rounded-lg border border-gray-300 bg-white shadow-md">
          {times.map((time) => (
            <div
              key={time}
              className="cursor-pointer rounded-md p-2 hover:bg-gray-100"
              onClick={() => {
                setSelectedTime(time);
                onTimeSelect(time);
                setIsOpen(false);
              }}
            >
              {time}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomTimePicker;
