import { useState, useEffect, useRef } from "react";
import { ProductsRepository } from "../../domain/repository";
import { AuthRepository } from "../../domain/repository/AuthRepository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import PaginationComponent from "../components/PaginationComponent";
import { useAdminModelController } from "../hook/useAdminModelController";
import { ConfirmedQuote } from "../../domain/models/ConfirmedQuote";
import { PendingQuote, ProcessedQuote } from "../../domain/models";
import TableQuotes from "../components/admin/TableQuotes";

export function ConfirmedQuoteView({
  authRepository,
  productRepository,
  quotesRepository,
}: {
  authRepository: AuthRepository;
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const [confirmedQuotes, setConfirmedQuotes] = useState<ConfirmedQuote[]>([]);
  const [filteredQuotes, setFilteredQuotes] = useState<
    PendingQuote[] | ProcessedQuote[] | ConfirmedQuote[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { handleGetAllConfirmedQuotes } = useAdminModelController(
    productRepository,
    quotesRepository,
  );
  const itemsPerPage = 10;

  useEffect(() => {
    loadConfirmedQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, confirmedQuotes, currentPage]);

  const loadConfirmedQuotes = async () => {
    const confirmedQuotesResponse = await handleGetAllConfirmedQuotes();
    setConfirmedQuotes(confirmedQuotesResponse);
    setFilteredQuotes(confirmedQuotesResponse);
  };

  const filterQuotes = () => {
    const filtered = confirmedQuotes.filter((quote) =>
      quote.name.toLowerCase().includes(search.toLowerCase()),
    );
    setFilteredQuotes(filtered);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentQuotes = filteredQuotes.slice(startIndex, endIndex);

  // Callback per ricevere i dati ordinati
  const handleSortedQuotes = (
    sortedQuotes: PendingQuote[] | ProcessedQuote[] | ConfirmedQuote[],
  ) => {
    setFilteredQuotes(sortedQuotes);
  };

  return (
    <div className="min-h-full w-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <main className="mx-auto w-full px-6 md:px-24">
        <div className="my-4 flex items-center justify-between md:my-10">
          <h1 className="items-center justify-between text-4xl font-bold tracking-tight text-gray-900">
            Preventivi confermati
          </h1>
          <input
            ref={searchInputRef}
            type="text"
            name="search"
            id="search"
            value={search}
            placeholder="Cerca per nome cliente..."
            onChange={handleSearchChange}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            className="block w-1/3 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
          />
        </div>
        <div className="overflow-x-auto rounded-xl border border-gray-300 shadow-sm">
          <TableQuotes
            quotes={currentQuotes}
            quoteType={"confirmedQuotes"}
            onSort={handleSortedQuotes}
          />
        </div>
        <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <PaginationComponent
              currentPage={currentPage}
              totalPages={Math.ceil(filteredQuotes.length / itemsPerPage)}
              onPageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={filteredQuotes.length}
            />
          </div>
        </div>
      </main>
      <FooterComponent />
    </div>
  );
}
