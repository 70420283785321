import { PendingQuote, ProcessedQuote } from "../../../domain/models";

function ExtraInfoQuoteData({
  quote,
}: {
  quote: ProcessedQuote | PendingQuote;
}) {
  return (
    <table className="mt-4 w-full border-collapse overflow-hidden rounded-lg border outline outline-1 outline-gray-300">
      <tbody>
        <tr>
          <td
            className="border border-gray-300 px-4 py-2 font-bold"
            style={{ width: "50%" }}
          >
            Testo nella mail:
          </td>
          <td
            className="border border-gray-300 px-4 py-2"
            style={{ width: "50%" }}
          >
            {quote.text || "N/A"}
          </td>
        </tr>
        <tr>
          <td
            className="border border-gray-300 px-4 py-2 font-bold"
            style={{ width: "50%" }}
          >
            Costi di spedizione:
          </td>
          <td
            className="border border-gray-300 px-4 py-2"
            style={{ width: "50%" }}
          >
            {quote.shippingPrice != null && quote.shippingPrice !== 0
              ? `${quote.shippingPrice.toFixed(2).replace(".", ",")} €`
              : "N/A"}
          </td>
        </tr>
        <tr>
          <td
            className="border border-gray-300 px-4 py-2 font-bold"
            style={{ width: "50%" }}
          >
            Totale:
          </td>
          <td
            className="border border-gray-300 px-4 py-2"
            style={{ width: "50%" }}
          >
            {quote.totalPrice != null && quote.totalPrice !== 0
              ? `${quote.totalPrice.toFixed(2).replace(".", ",")} €`
              : "N/A"}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ExtraInfoQuoteData;
