import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import React, { useState, useRef, useEffect } from "react";

const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

interface DatePickerProps {
  onDateSelect: (date: Date) => void;
  internalDate?: Date;
  minDate: Date | null;
}

const DatePickerCustom: React.FC<DatePickerProps> = ({
  onDateSelect,
  internalDate,
  minDate,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    internalDate || null,
  );
  const [currentDate, setCurrentDate] = useState(new Date());
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (internalDate && !selectedDate) {
      setSelectedDate(internalDate);
    }
  }, [internalDate, selectedDate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setShowCalendar(false);
      }
    };

    if (showCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  useEffect(() => {
    // Reset selected date when minDate changes
    setSelectedDate(null);
  }, [minDate]);

  const handleInputClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateSelect = (date: Date | null) => {
    if (date === null) return;
    if (minDate && date < minDate) {
      return;
    }
    setSelectedDate(date);
    if (onDateSelect) onDateSelect(date);
    setShowCalendar(false); // Chiudi il calendario dopo la selezione
  };

  const getDaysOfMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const renderDays = () => {
    const days = [];
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const daysInMonth = getDaysOfMonth(year, month);

    // Adjust firstDayOfMonth to start from Monday (0 = Monday, ..., 6 = Sunday)
    const adjustedFirstDay = (firstDayOfMonth + 6) % 7;

    // Add empty days for the previous month
    for (let i = 0; i < adjustedFirstDay; i++) {
      days.push(<div key={`empty-${i}`} className="p-2" />);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const current = new Date(year, month, i);
      // Disabilita i giorni passati o quelli antecedenti a minDate
      const isDisabled = current < new Date() || (minDate && current < minDate);
      days.push(
        <div
          key={i}
          className={`cursor-pointer p-2 text-center hover:rounded-lg hover:bg-gray-100 ${
            selectedDate && selectedDate.getDate() === i
              ? "rounded-md bg-palette-primary font-bold text-white"
              : isDisabled
                ? "pointer-events-none cursor-not-allowed text-gray-400" // Disabilita clic
                : "text-gray-700"
          }`}
          onClick={() => !isDisabled && handleDateSelect(current)}
        >
          {i}
        </div>,
      );
    }
    return days;
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString("it-IT", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        value={
          selectedDate ? capitalizeFirstLetter(formatDate(selectedDate)) : ""
        }
        readOnly
        className="w-full cursor-pointer rounded-md border border-gray-300 p-2 text-sm focus:border-purple-500 focus:outline-none"
        onClick={handleInputClick}
      />
      {showCalendar && (
        <div
          ref={calendarRef}
          className="absolute top-12 z-10 w-full rounded-md border border-gray-300 bg-white p-3 shadow-lg"
        >
          <div className="mb-2 flex items-center justify-between">
            <div
              className="cursor-pointer"
              onClick={() =>
                setCurrentDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() - 1,
                    1,
                  ),
                )
              }
            >
              <CaretLeft />
            </div>
            <div className="text-lg font-semibold">
              {months[currentDate.getMonth()]} {currentDate.getFullYear()}
            </div>
            <div
              className="cursor-pointer"
              onClick={() =>
                setCurrentDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() + 1,
                    1,
                  ),
                )
              }
            >
              <CaretRight />
            </div>
          </div>
          <div className="mt-4 grid grid-cols-7 gap-1 text-center text-xs">
            {["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"].map((day) => (
              <div
                key={day}
                className="text-center font-semibold text-gray-600"
              >
                {day}
              </div>
            ))}
            {renderDays()}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePickerCustom;
