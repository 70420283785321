import { useState, useRef, useEffect } from "react";
import { CircularLoaderComponent } from "../CircularLoaderComponent";
import { ProductData } from "../../../domain/models";

export function EditProductImageComponent({
  product,
  onCoverImageChange,
  onProductImagesChange,
  onImageToDeleteChange,
}: {
  product: ProductData;
  onCoverImageChange: (coverImage: File) => void;
  onProductImagesChange: (productImages: File[]) => void;
  onImageToDeleteChange: (productImageToDelete: string) => void;
}) {
  const [coverImagePreview, setCoverImagePreview] = useState<string>("");
  const [imagePreviews, setImagePreviews] = useState<string[]>(
    product.images.slice(1),
  );
  const ref = useRef<HTMLDivElement | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    if (product.images.length > 0) {
      setCoverImagePreview(product.images[0]);
      setShowLoader(false);
    }
  }, [product.images]);

  const onAddProductCover = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      onCoverImageChange(file);

      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const preview = event.target.result.toString();
          setCoverImagePreview(preview);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onAddProductImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const images: FileList = e.target.files;
      const newImages: File[] = Array.from(images);
      onProductImagesChange(newImages);

      for (let i = 0; i < images.length; i++) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const preview = event.target.result.toString();
            setImagePreviews((prevPreviews) => [...prevPreviews, preview]);
          }
        };
        reader.readAsDataURL(images[i]);
      }
    }
  };

  const handleImageDelete = (index: number) => {
    onImageToDeleteChange(imagePreviews[index]);
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);
  };

  if (showLoader) {
    return (
      <div className="mx-auto flex h-[500px] w-full items-center justify-center">
        <CircularLoaderComponent />
      </div>
    );
  }
  return (
    <div className="w-full max-w-md md:w-1/2">
      <div className="relative">
        <div className="col-span-full">
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Immagine di copertina
          </label>
          <div className="mt-2 rounded-lg border border-dashed border-gray-900/25 p-6">
            <div className="flex flex-col items-center space-y-2">
              <img
                src={coverImagePreview}
                alt="Immagine cover"
                className="h-[300px] max-w-full rounded-md"
              />
              <label
                htmlFor="cover-upload"
                className="relative cursor-pointer font-semibold text-palette-primary hover:text-palette-dark"
              >
                <span>Sostituisci immagine</span>
                <input
                  id="cover-upload"
                  onChange={onAddProductCover}
                  type="file"
                  accept=".jpg,.png,.jpeg,.svg"
                  className="sr-only"
                  required
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div
          ref={ref}
          style={{ scrollBehavior: "smooth" }}
          className="flex w-full space-x-2 overflow-auto"
        >
          <div className="col-span-full w-full">
            <label
              htmlFor="product-images"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Immagini prodotti
            </label>

            <div className="mt-2 grid grid-cols-5 gap-3 rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
              {imagePreviews.map((preview, index) => (
                <div
                  key={index}
                  className="relative flex items-center justify-center"
                >
                  <img
                    src={preview}
                    alt={`Immagine ${index}`}
                    className="h-auto w-full rounded-md"
                  />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity hover:opacity-100">
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 cursor-pointer text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={() => handleImageDelete(index)}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-span-full text-center text-sm leading-6 text-gray-600">
                <label
                  htmlFor="image-upload"
                  className="relative cursor-pointer font-semibold text-palette-primary hover:text-palette-dark"
                >
                  <span>Carica altre foto</span>
                  <input
                    id="image-upload"
                    onChange={onAddProductImages}
                    type="file"
                    accept=".jpg,.png,.jpeg,.svg"
                    className="sr-only"
                    required
                    multiple
                    max="10"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
